export class PrescriptionModel {
    constructor() {
        this.Id = "";
        this.Code = ""
        this.Description = "";
        this.PrescriptionDate = "";
        this.DiagnosysWithICD = "";
        this.Reporting = "";
        this.Typology = "";
        this.Prescriptor = "";
        this.Consumables = "";
        this.Accessories = "";
        this.StartDate = "";
        this.EndDate = "";
        this.CustomerId = ""
    }
}