import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";

export function PatientInfoMenu() {

    const [active, setActive] = useState("patientGraph");

    const { currentSpinnerState, currentPatientState } = useSelector(
        (state) => ({
            currentSpinnerState: state.spinner,
            currentPatientState: state.patients
        }),
        shallowEqual
    );

    const { count } = currentSpinnerState;
    const { openedPrescriptionAccordion } = currentPatientState;

    useEffect(() => {
        if (count === 0) {
            setOffset();

            window.onscroll = setActiveOnScroll;
        }

        return () => {
            window.onscroll = null;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, openedPrescriptionAccordion]);

    let patientGraphOffsetTop;
    let patientRegistryOffsetTop;
    let patientPrescriptionsOffsetTop;
    let patientProvisionsOffsetTop;
    let patientDevicesOffsetTop;
    let patientAccessoriesOffsetTop;
    let patientConsumableOffsetTop;
    let patientMedicinalsOffsetTop;
    let patientInvoicesOffsetTop;

    function setOffset() {
        patientGraphOffsetTop = document.getElementsByName('patientGraph')[0].offsetTop;
        patientRegistryOffsetTop = document.getElementsByName('patientRegistry')[0].offsetTop - 1;
        patientPrescriptionsOffsetTop = document.getElementsByName('patientPrescriptions')[0].offsetTop - 1;
        patientProvisionsOffsetTop = document.getElementsByName('patientProvisions')[0].offsetTop - 1;
        patientDevicesOffsetTop = document.getElementsByName('patientDevices')[0].offsetTop - 1;
        patientAccessoriesOffsetTop = document.getElementsByName('patientAccessories')[0].offsetTop - 1;
        patientConsumableOffsetTop = document.getElementsByName('patientConsumable')[0].offsetTop - 1;
        patientMedicinalsOffsetTop = document.getElementsByName('patientMedicinals')[0].offsetTop - 1;
        patientInvoicesOffsetTop = document.getElementsByName('patientInvoices')[0].offsetTop - 1;
    }

    function setActiveOnScroll() {
        if (window.scrollY >= patientGraphOffsetTop && window.scrollY <= patientRegistryOffsetTop) {
            setActive("patientGraph");
        } else if (window.scrollY >= patientRegistryOffsetTop && window.scrollY <= patientPrescriptionsOffsetTop) {
            setActive("patientRegistry");
        } else if (window.scrollY >= patientPrescriptionsOffsetTop && window.scrollY <= patientProvisionsOffsetTop) {
            setActive("patientPrescriptions");
        } else if (window.scrollY >= patientProvisionsOffsetTop && window.scrollY <= patientDevicesOffsetTop) {
            setActive("patientProvisions");
        } else if (window.scrollY >= patientDevicesOffsetTop && window.scrollY <= patientAccessoriesOffsetTop) {
            setActive("patientDevices");
        } else if (window.scrollY >= patientAccessoriesOffsetTop && window.scrollY <= patientConsumableOffsetTop) {
            setActive("patientAccessories");
        } else if (window.scrollY >= patientConsumableOffsetTop && window.scrollY <= patientMedicinalsOffsetTop) {
            setActive("patientConsumable");
        } else if (window.scrollY >= patientMedicinalsOffsetTop && window.scrollY <= patientInvoicesOffsetTop) {
            setActive("patientMedicinals");
        } else if (window.scrollY >= patientInvoicesOffsetTop) {
            setActive("patientInvoices");
        }
    }

    return (
        <div className="navi navi-hover navi-active navi-link-rounded navi-bold navi-icon-center navi-light-icon">
            <div className="navi-item my-2">
                <a href="#patientGraph" className={active === "patientGraph" ? "navi-link active" : "navi-link"} onClick={(evt) => {
                    evt.preventDefault();
                    window.onscroll = null;
                    window.scrollTo(0, document.getElementsByName('patientGraph')[0].offsetTop);
                    setActive("patientGraph");
                    setOffset();
                    setTimeout(() => {
                        window.onscroll = setActiveOnScroll;
                    }, 100);
                }}>
                    <span className="navi-icon mr-4">
                        <span className="svg-icon svg-icon-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path d="M7,11 L15,11 C16.1045695,11 17,10.1045695 17,9 L17,8 L19,8 L19,9 C19,11.209139 17.209139,13 15,13 L7,13 L7,15 C7,15.5522847 6.55228475,16 6,16 C5.44771525,16 5,15.5522847 5,15 L5,9 C5,8.44771525 5.44771525,8 6,8 C6.55228475,8 7,8.44771525 7,9 L7,11 Z" fill="#000000" opacity="0.3" />
                                    <path d="M6,21 C7.1045695,21 8,20.1045695 8,19 C8,17.8954305 7.1045695,17 6,17 C4.8954305,17 4,17.8954305 4,19 C4,20.1045695 4.8954305,21 6,21 Z M6,23 C3.790861,23 2,21.209139 2,19 C2,16.790861 3.790861,15 6,15 C8.209139,15 10,16.790861 10,19 C10,21.209139 8.209139,23 6,23 Z" fill="#000000" fillRule="nonzero" />
                                    <path d="M18,7 C19.1045695,7 20,6.1045695 20,5 C20,3.8954305 19.1045695,3 18,3 C16.8954305,3 16,3.8954305 16,5 C16,6.1045695 16.8954305,7 18,7 Z M18,9 C15.790861,9 14,7.209139 14,5 C14,2.790861 15.790861,1 18,1 C20.209139,1 22,2.790861 22,5 C22,7.209139 20.209139,9 18,9 Z" fill="#000000" fillRule="nonzero" />
                                    <path d="M6,7 C7.1045695,7 8,6.1045695 8,5 C8,3.8954305 7.1045695,3 6,3 C4.8954305,3 4,3.8954305 4,5 C4,6.1045695 4.8954305,7 6,7 Z M6,9 C3.790861,9 2,7.209139 2,5 C2,2.790861 3.790861,1 6,1 C8.209139,1 10,2.790861 10,5 C10,7.209139 8.209139,9 6,9 Z" fill="#000000" fillRule="nonzero" />
                                </g>
                            </svg>
                        </span>
                    </span>
                    <span className="navi-text font-weight-bolder font-size-lg">Sintesi dello stato e prestazioni in corso</span>
                </a>
            </div >
            <div className="navi-item my-2">
                <a href="#patientRegistry" className={active === "patientRegistry" ? "navi-link active" : "navi-link"} onClick={(evt) => {
                    evt.preventDefault();
                    window.onscroll = null;
                    window.scrollTo(0, document.getElementsByName('patientRegistry')[0].offsetTop);
                    setActive("patientRegistry");
                    setOffset();
                    setTimeout(() => {
                        window.onscroll = setActiveOnScroll;
                    }, 100);
                }}>
                    <span className="navi-icon mr-4">
                        <span className="svg-icon svg-icon-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path d="M18,2 L20,2 C21.6568542,2 23,3.34314575 23,5 L23,19 C23,20.6568542 21.6568542,22 20,22 L18,22 L18,2 Z" fill="#000000" opacity="0.3" />
                                    <path d="M5,2 L17,2 C18.6568542,2 20,3.34314575 20,5 L20,19 C20,20.6568542 18.6568542,22 17,22 L5,22 C4.44771525,22 4,21.5522847 4,21 L4,3 C4,2.44771525 4.44771525,2 5,2 Z M12,11 C13.1045695,11 14,10.1045695 14,9 C14,7.8954305 13.1045695,7 12,7 C10.8954305,7 10,7.8954305 10,9 C10,10.1045695 10.8954305,11 12,11 Z M7.00036205,16.4995035 C6.98863236,16.6619875 7.26484009,17 7.4041679,17 C11.463736,17 14.5228466,17 16.5815,17 C16.9988413,17 17.0053266,16.6221713 16.9988413,16.5 C16.8360465,13.4332455 14.6506758,12 11.9907452,12 C9.36772908,12 7.21569918,13.5165724 7.00036205,16.4995035 Z" fill="#000000" />
                                </g>
                            </svg>
                        </span>
                    </span>
                    <span className="navi-text font-weight-bolder font-size-lg">Anagrafica del Paziente</span>
                </a>
            </div >
            <div className="navi-item my-2">
                <a href="#patientPrescriptions" className={active === "patientPrescriptions" ? "navi-link active" : "navi-link"} onClick={(evt) => {
                    evt.preventDefault();
                    window.onscroll = null;
                    window.scrollTo(0, document.getElementsByName('patientPrescriptions')[0].offsetTop);
                    setActive("patientPrescriptions");
                    setOffset();
                    setTimeout(() => {
                        window.onscroll = setActiveOnScroll;
                    }, 100);
                }}>
                    <span className="navi-icon mr-4">
                        <span className="svg-icon svg-icon-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3" />
                                    <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000" />
                                    <rect fill="#000000" opacity="0.3" x="10" y="9" width="7" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.3" x="7" y="9" width="2" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.3" x="7" y="13" width="2" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.3" x="10" y="13" width="7" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.3" x="7" y="17" width="2" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.3" x="10" y="17" width="7" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </span>
                    <span className="navi-text font-weight-bolder font-size-lg">Trattamenti del Paziente</span>
                </a>
            </div >
            <div className="navi-item my-2">
                <a href="#patientProvisions" className={active === "patientProvisions" ? "navi-link active" : "navi-link"} onClick={(evt) => {
                    evt.preventDefault();
                    window.onscroll = null;
                    window.scrollTo(0, document.getElementsByName('patientProvisions')[0].offsetTop);
                    setActive("patientProvisions");
                    setOffset();
                    setTimeout(() => {
                        window.onscroll = setActiveOnScroll;
                    }, 100);
                }}>
                    <span className="navi-icon mr-4">
                        <span className="svg-icon svg-icon-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <polygon points="0 0 24 0 24 24 0 24" />
                                    <rect fill="#000000" opacity="0.3" transform="translate(10.000000, 12.000000) rotate(-90.000000) translate(-10.000000, -12.000000) " x="9" y="5" width="2" height="14" rx="1" />
                                    <rect fill="#000000" opacity="0.3" x="19" y="3" width="2" height="18" rx="1" />
                                    <path d="M7.70710318,15.7071045 C7.31657888,16.0976288 6.68341391,16.0976288 6.29288961,15.7071045 C5.90236532,15.3165802 5.90236532,14.6834152 6.29288961,14.2928909 L12.2928896,8.29289093 C12.6714686,7.914312 13.281055,7.90106637 13.675721,8.26284357 L19.675721,13.7628436 C20.08284,14.136036 20.1103429,14.7686034 19.7371505,15.1757223 C19.3639581,15.5828413 18.7313908,15.6103443 18.3242718,15.2371519 L13.0300721,10.3841355 L7.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(12.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-12.999999, -11.999997) " />
                                </g>
                            </svg>
                        </span>
                    </span>
                    <span className="navi-text font-weight-bolder font-size-lg">Prestazioni svolte e pianificate</span>
                </a>
            </div >
            <div className="navi-item my-2">
                <a href="#patientDevices" className={active === "patientDevices" ? "navi-link active" : "navi-link"} onClick={(evt) => {
                    evt.preventDefault();
                    window.onscroll = null;
                    window.scrollTo(0, document.getElementsByName('patientDevices')[0].offsetTop);
                    setActive("patientDevices");
                    setOffset();
                    setTimeout(() => {
                        window.onscroll = setActiveOnScroll;
                    }, 100);
                }}>
                    <span className="navi-icon mr-4">
                        <span className="svg-icon svg-icon-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path d="M15,15 L15,10 C15,9.44771525 15.4477153,9 16,9 C16.5522847,9 17,9.44771525 17,10 L17,15 L20,15 C21.1045695,15 22,15.8954305 22,17 L22,19 C22,20.1045695 21.1045695,21 20,21 L4,21 C2.8954305,21 2,20.1045695 2,19 L2,17 C2,15.8954305 2.8954305,15 4,15 L15,15 Z M5,17 C4.44771525,17 4,17.4477153 4,18 C4,18.5522847 4.44771525,19 5,19 L10,19 C10.5522847,19 11,18.5522847 11,18 C11,17.4477153 10.5522847,17 10,17 L5,17 Z" fill="#000000" />
                                    <path d="M20.5,7.7155722 L19.2133304,8.85714286 C18.425346,7.82897283 17.2569914,7.22292937 15.9947545,7.22292937 C14.7366498,7.22292937 13.571742,7.82497398 12.7836854,8.84737587 L11.5,7.70192243 C12.6016042,6.27273291 14.2349886,5.42857143 15.9947545,5.42857143 C17.7603123,5.42857143 19.3985009,6.27832502 20.5,7.7155722 Z M23.5,5.46053062 L22.1362873,6.57142857 C20.629466,4.78945909 18.4012066,3.73944576 15.9963045,3.73944576 C13.5947271,3.73944576 11.3692392,4.78653417 9.8623752,6.56427829 L8.5,5.45180053 C10.340077,3.28094376 13.0626024,2 15.9963045,2 C18.934073,2 21.6599771,3.28451636 23.5,5.46053062 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                </g>
                            </svg>
                        </span>
                    </span>
                    <span className="navi-text font-weight-bolder font-size-lg">Dispositivi</span>
                </a>
            </div >
            <div className="navi-item my-2">
                <a href="#patientAccessories" className={active === "patientAccessories" ? "navi-link active" : "navi-link"} onClick={(evt) => {
                    evt.preventDefault();
                    window.onscroll = null;
                    window.scrollTo(0, document.getElementsByName('patientAccessories')[0].offsetTop);
                    setActive("patientAccessories");
                    setOffset();
                    setTimeout(() => {
                        window.onscroll = setActiveOnScroll;
                    }, 100);
                }}>
                    <span className="navi-icon mr-4">
                        <span className="svg-icon svg-icon-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <polygon points="0 0 24 0 24 24 0 24" />
                                    <path d="M22,15 L22,19 C22,20.1045695 21.1045695,21 20,21 L8,21 C5.790861,21 4,19.209139 4,17 C4,14.790861 5.790861,13 8,13 L20,13 C21.1045695,13 22,13.8954305 22,15 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z" fill="#000000" opacity="0.3" />
                                    <path d="M15.5421357,5.69999981 L18.3705628,8.52842693 C19.1516114,9.30947552 19.1516114,10.5758055 18.3705628,11.3568541 L9.88528147,19.8421354 C8.3231843,21.4042326 5.79052439,21.4042326 4.22842722,19.8421354 C2.66633005,18.2800383 2.66633005,15.7473784 4.22842722,14.1852812 L12.7137086,5.69999981 C13.4947572,4.91895123 14.7610871,4.91895123 15.5421357,5.69999981 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z" fill="#000000" opacity="0.3" />
                                    <path d="M5,3 L9,3 C10.1045695,3 11,3.8954305 11,5 L11,17 C11,19.209139 9.209139,21 7,21 C4.790861,21 3,19.209139 3,17 L3,5 C3,3.8954305 3.8954305,3 5,3 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z" fill="#000000" />
                                </g>
                            </svg>
                        </span>
                    </span>
                    <span className="navi-text font-weight-bolder font-size-lg">Accessori</span>
                </a>
            </div >
            <div className="navi-item my-2">
                <a href="#patientConsumable" className={active === "patientConsumable" ? "navi-link active" : "navi-link"} onClick={(evt) => {
                    evt.preventDefault();
                    window.onscroll = null;
                    window.scrollTo(0, document.getElementsByName('patientConsumable')[0].offsetTop);
                    setActive("patientConsumable");
                    setOffset();
                    setTimeout(() => {
                        window.onscroll = setActiveOnScroll;
                    }, 100);
                }}>
                    <span className="navi-icon mr-4">
                        <span className="svg-icon svg-icon-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path d="M20.4061385,6.73606154 C20.7672665,6.89656288 21,7.25468437 21,7.64987309 L21,16.4115967 C21,16.7747638 20.8031081,17.1093844 20.4856429,17.2857539 L12.4856429,21.7301984 C12.1836204,21.8979887 11.8163796,21.8979887 11.5143571,21.7301984 L3.51435707,17.2857539 C3.19689188,17.1093844 3,16.7747638 3,16.4115967 L3,7.64987309 C3,7.25468437 3.23273352,6.89656288 3.59386153,6.73606154 L11.5938615,3.18050598 C11.8524269,3.06558805 12.1475731,3.06558805 12.4061385,3.18050598 L20.4061385,6.73606154 Z" fill="#000000" opacity="0.3" />
                                    <polygon fill="#000000" points="14.9671522 4.22441676 7.5999999 8.31727912 7.5999999 12.9056825 9.5999999 13.9056825 9.5999999 9.49408582 17.25507 5.24126912" />
                                </g>
                            </svg>
                        </span>
                    </span>
                    <span className="navi-text font-weight-bolder font-size-lg">Consumabili</span>
                </a>
            </div >
            <div className="navi-item my-2">
                <a href="#patientMedicinals" className={active === "patientMedicinals" ? "navi-link active" : "navi-link"} onClick={(evt) => {
                    evt.preventDefault();
                    window.onscroll = null;
                    window.scrollTo(0, document.getElementsByName('patientMedicinals')[0].offsetTop);
                    setActive("patientMedicinals");
                    setOffset();
                    setTimeout(() => {
                        window.onscroll = setActiveOnScroll;
                    }, 100);
                }}>
                    <span className="navi-icon mr-4">
                        <span className="svg-icon svg-icon-lg">
                            <i className="fas fa-pills" />
                        </span>
                    </span>
                    <span className="navi-text font-weight-bolder font-size-lg">Farmaci</span>
                </a>
            </div >
            <div className="navi-item my-2">
                <a href="#patientInvoices" className={active === "patientInvoices" ? "navi-link active" : "navi-link"} onClick={(evt) => {
                    evt.preventDefault();
                    window.onscroll = null;
                    window.scrollTo(0, document.getElementsByName('patientInvoices')[0].offsetTop);
                    setActive("patientInvoices");
                    setOffset();
                    setTimeout(() => {
                        window.onscroll = setActiveOnScroll;
                    }, 100);
                }}>
                    <span className="navi-icon mr-4">
                        <span className="svg-icon svg-icon-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <rect fill="#000000" opacity="0.3" x="7" y="4" width="10" height="4" />
                                    <path d="M7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,20 C19,21.1045695 18.1045695,22 17,22 L7,22 C5.8954305,22 5,21.1045695 5,20 L5,4 C5,2.8954305 5.8954305,2 7,2 Z M8,12 C8.55228475,12 9,11.5522847 9,11 C9,10.4477153 8.55228475,10 8,10 C7.44771525,10 7,10.4477153 7,11 C7,11.5522847 7.44771525,12 8,12 Z M8,16 C8.55228475,16 9,15.5522847 9,15 C9,14.4477153 8.55228475,14 8,14 C7.44771525,14 7,14.4477153 7,15 C7,15.5522847 7.44771525,16 8,16 Z M12,12 C12.5522847,12 13,11.5522847 13,11 C13,10.4477153 12.5522847,10 12,10 C11.4477153,10 11,10.4477153 11,11 C11,11.5522847 11.4477153,12 12,12 Z M12,16 C12.5522847,16 13,15.5522847 13,15 C13,14.4477153 12.5522847,14 12,14 C11.4477153,14 11,14.4477153 11,15 C11,15.5522847 11.4477153,16 12,16 Z M16,12 C16.5522847,12 17,11.5522847 17,11 C17,10.4477153 16.5522847,10 16,10 C15.4477153,10 15,10.4477153 15,11 C15,11.5522847 15.4477153,12 16,12 Z M16,16 C16.5522847,16 17,15.5522847 17,15 C17,14.4477153 16.5522847,14 16,14 C15.4477153,14 15,14.4477153 15,15 C15,15.5522847 15.4477153,16 16,16 Z M16,20 C16.5522847,20 17,19.5522847 17,19 C17,18.4477153 16.5522847,18 16,18 C15.4477153,18 15,18.4477153 15,19 C15,19.5522847 15.4477153,20 16,20 Z M8,18 C7.44771525,18 7,18.4477153 7,19 C7,19.5522847 7.44771525,20 8,20 L12,20 C12.5522847,20 13,19.5522847 13,19 C13,18.4477153 12.5522847,18 12,18 L8,18 Z M7,4 L7,8 L17,8 L17,4 L7,4 Z" fill="#000000" />
                                </g>
                            </svg>
                        </span>
                    </span>
                    <span className="navi-text font-weight-bolder font-size-lg">Importi Fatturati</span>
                </a>
            </div >
        </div >
    );
}