export class InvoiceTableModel {
    constructor() {
        this.Id = "";
        this.DocumentType = 0;
        this.CustomerId = "";
        this.DocumentCode = "";
        this.InvoiceDate = "";
        this.Amount = "";
        this.DocumentLink = "";
        this.District = "";
    }
}