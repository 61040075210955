import React from "react";
import { PatientsLoadingDialog } from "../../../component/table/PatientsLoadingDialog";
import { PatientInfoUIProvider } from "./PatientInfoUIContext";
import { PatientInfoHome } from "./PatientInfoHome";

export const PatientsInfoPage = ({ history, id }) => {
    // const patientInfoUIEvents = {
    //     // openEditInvoicePage: (id) => {
    //     //     history.push(`/patients/${id}/richiestamodificafattura`);
    //     // },
    // }

    return (
        <PatientInfoUIProvider /*patientInfoUIEvents={patientInfoUIEvents}*/>
            <PatientsLoadingDialog />
            <PatientInfoHome history={history} id={id} />
        </PatientInfoUIProvider>
    );
}