import React, { useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
    Input
} from "../../../../_metronic/_partials/controls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import KTWizard from "../../../../_metronic/_assets/js/components/wizard";
import "../../../../_metronic/_assets/sass/pages/wizard/wizard-3.scss";
import { Formik, Form, Field } from "formik";
import { PatientGenderTitles, PatientStatusTitles } from "../PatientsUIHelpers";
import * as actions from "../../../actions/generic/editActions";

export function PatientEditHome() {

    const suhbeader = useSubheader();
    const dispatch = useDispatch();

    const { patientById } = useSelector(
        (state) => ({
            patientById: state.patients.patientById
        }),
        shallowEqual
    );

    useEffect(() => {
        suhbeader.setTitle(patientById ? `Pazienti - Segnalazione paziente - '${patientById.FullName}'` : "");
        var options = {
            startStep: 1,
            clickableSteps: true
        }
        new KTWizard('patientWizard', options);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientById]);

    const customLabel = (props) => {
        return (
            props.field.name === "Addresses"
                ? <>
                    {
                        props.field.value.map((el, index) => {
                            return (
                                <div className="my-1" key={index}>
                                    <span>{el}</span>
                                </div>
                            )
                        })
                    }
                </>
                : <span>
                    {props.field.name === "Gender" ? PatientGenderTitles[props.field.value] : props.field.value}
                </span>
        );
    }

    const customInput = (props) => {
        let textAreaValue = "";
        if (props.field.name === "Addresses" && props.field.value) {
            props.field.value.forEach((el) => {
                textAreaValue += el + "\r\n\r\n";
            });
        }
        return (
            props.field.name === "Gender"
                ? <>
                    <label>Sesso</label>
                    <input type="text" className="form-control" name="Gender" disabled value={PatientGenderTitles[props.field.value]} />
                </>
                : <>
                    <label>Indirizzi Alternativi</label>
                    <textarea name="Addresses" className="form-control" disabled rows={6} value={textAreaValue} />
                </>
        );
    }

    return (
        <Card>
            <CardHeader title="">
                <CardHeaderToolbar>
                    <Button
                        className="btn btn-secondary font-weight-bold px-9 py-4"
                        onClick={() => {
                            window.history.back();
                        }}
                    >
                        Annulla
                    </Button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <div id="patientWizard" className="wizard wizard-3" data-wizard-state="step-first" data-wizard-clickable="true">
                    <div className="wizard-nav">
                        <div className="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
                            <div className="wizard-step" data-wizard-type="step" data-wizard-state="current">
                                <div className="wizard-label">
                                    <h3 className="wizard-title">
                                        <span>1.</span>Generalità</h3>
                                    <div className="wizard-bar"></div>
                                </div>
                            </div>
                            <div className="wizard-step" data-wizard-type="step">
                                <div className="wizard-label">
                                    <h3 className="wizard-title">
                                        <span>2.</span>Indirizzo</h3>
                                    <div className="wizard-bar"></div>
                                </div>
                            </div>
                            <div className="wizard-step" data-wizard-type="step">
                                <div className="wizard-label">
                                    <h3 className="wizard-title">
                                        <span>3.</span>Contatti</h3>
                                    <div className="wizard-bar"></div>
                                </div>
                            </div>
                            <div className="wizard-step" data-wizard-type="step">
                                <div className="wizard-label">
                                    <h3 className="wizard-title">
                                        <span>4.</span>Note</h3>
                                    <div className="wizard-bar"></div>
                                </div>
                            </div>
                            <div className="wizard-step" data-wizard-type="step">
                                <div className="wizard-label">
                                    <h3 className="wizard-title">
                                        <span>5</span>Revisione</h3>
                                    <div className="wizard-bar"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center px-8 px-lg-10 div-custom">
                        <div className="col-xl-12 col-xxl-7">
                            <Formik
                                enableReinitialize={true}
                                initialValues={patientById}
                                onSubmit={(values) => {
                                    const model = {
                                        GenericObject: {
                                            Id: values.Id,
                                            FirstName: values.FirstName,
                                            LastName: values.LastName,
                                            BirthDate: values.BirthDate,
                                            Gender: PatientGenderTitles[values.Gender],
                                            FiscalCode: values.FiscalCode,
                                            District: values.District,
                                            PatientState: PatientStatusTitles[values.PatientState],
                                            Email: values.Email,
                                            Phone: values.Phone,
                                            HomeAddress: values.HomeAddress,
                                            ResidenceAddress: values.ResidenceAddress,
                                            Addresses: values.Addresses,
                                            Reporting: values.Reporting,
                                            CustomerId: values.CustomerId,
                                            Section: "Paziente"
                                        }
                                    }
                                    dispatch(actions.editEntity(model));
                                }}
                            >
                                {({ handleSubmit }) => (
                                    <>
                                        <Form className="form" onSubmit={handleSubmit}>
                                            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                                <h4 className="mb-10 font-weight-bold text-dark">Generalità del Paziente</h4>
                                                <div className="form-group row">
                                                    <div className="col-xl-6">
                                                        <Field
                                                            name="LastName"
                                                            component={Input}
                                                            label="Cognome"
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <Field
                                                            name="FirstName"
                                                            component={Input}
                                                            label="Nome"
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-xl-4">
                                                        <Field
                                                            name="BirthDate"
                                                            component={Input}
                                                            label="Data di nascita"
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <Field
                                                            name="Gender"
                                                            component={customInput}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <Field
                                                            name="FiscalCode"
                                                            component={Input}
                                                            label="Codice Fiscale"
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-xl-6">
                                                        <Field
                                                            name="District"
                                                            component={Input}
                                                            label="Distretto Sanitario"
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <Field
                                                            name="RegionalCode"
                                                            component={Input}
                                                            label="Codice Regionale Assistito"
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pb-5" data-wizard-type="step-content">
                                                <h4 className="mb-10 font-weight-bold text-dark">Indirizzi</h4>
                                                <div className="form-group row">
                                                    <div className="col-xl-6">
                                                        <Field
                                                            name="ResidenceAddress"
                                                            component={Input}
                                                            label="Indirizzo di Residenza"
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <Field
                                                            name="HomeAddress"
                                                            component={Input}
                                                            label="Indirizzo di Domicilio"
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-xl-12">
                                                        <Field
                                                            name="Addresses"
                                                            component={customInput}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pb-5" data-wizard-type="step-content">
                                                <h4 className="mb-10 font-weight-bold text-dark">Contatti del Paziente</h4>
                                                <div className="form-group row">
                                                    <div className="col-xl-6">
                                                        <Field
                                                            name="Phone"
                                                            component={Input}
                                                            label="Telefono"
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <Field
                                                            name="Email"
                                                            component={Input}
                                                            label="Email"
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-xl-12">
                                                        <Field
                                                            name="CareGiverNominative"
                                                            component={Input}
                                                            label="Assistente"
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pb-5" data-wizard-type="step-content">
                                                <h4 className="mb-10 font-weight-bold text-dark">Segnalazione</h4>
                                                <div className="form-group row">
                                                    <Field
                                                        name="Reporting"
                                                        component="textarea"
                                                        rows="5"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="pb-5" data-wizard-type="step-content">
                                                {
                                                    patientById
                                                        ? <>
                                                            <h4 className="mb-10 font-weight-bold text-dark">Revisione dati inseriti</h4>
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <h6 className="font-weight-bolder mb-3">Generalità:</h6>
                                                                    <div className="text-dark-50 line-height-lg">
                                                                        <div>
                                                                            <span>Cognome: </span>
                                                                            <Field
                                                                                name="LastName"
                                                                                component={customLabel}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <span>Nome: </span>
                                                                            <Field
                                                                                name="FirstName"
                                                                                component={customLabel}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <span>Data Di Nascita: </span>
                                                                            <Field
                                                                                name="BirthDate"
                                                                                component={customLabel}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <span>Sesso: </span>
                                                                            <Field
                                                                                name="Gender"
                                                                                component={customLabel}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <span>Codice Fiscale: </span>
                                                                            <Field
                                                                                name="FiscalCode"
                                                                                component={customLabel}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <span>Distretto: </span>
                                                                            <Field
                                                                                name="District"
                                                                                component={customLabel}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <span>Codice Regionale: </span>
                                                                            <Field
                                                                                name="RegionalCode"
                                                                                component={customLabel}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <h6 className="font-weight-bolder mb-3">Indirizzo di residenza:</h6>
                                                                    <div className="text-dark-50 line-height-lg">
                                                                        <div>
                                                                            <Field
                                                                                name="ResidenceAddress"
                                                                                component={customLabel}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <h6 className="font-weight-bolder mb-3 mt-3 h6-custom">Indirizzo di domicilio:</h6>
                                                                    <div className="text-dark-50 line-height-lg">
                                                                        <div>
                                                                            <Field
                                                                                name="HomeAddress"
                                                                                component={customLabel}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <h6 className="font-weight-bolder mb-3 mt-3 h6-custom">Indirizzi alternativi:</h6>
                                                                    <div className="text-dark-50 line-height-lg">
                                                                        <div>
                                                                            <Field
                                                                                name="Addresses"
                                                                                component={customLabel}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="separator separator-dashed my-5" />
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <h6 className="font-weight-bolder mb-3">Contatti:</h6>
                                                                    <div className="text-dark-50 line-height-lg">
                                                                        <div>
                                                                            <span>Telefono: </span>
                                                                            <Field
                                                                                name="Phone"
                                                                                component={customLabel}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <span>Email: </span>
                                                                            <Field
                                                                                name="Email"
                                                                                component={customLabel}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <span>Nominativo Assistente: </span>
                                                                            <Field
                                                                                name="CareGiverNominative"
                                                                                component={customLabel}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <h6 className="font-weight-bolder mb-3">Note</h6>
                                                                    <div className="text-dark-50 line-height-lg">
                                                                        <div>
                                                                            <Field
                                                                                name="Reporting"
                                                                                component={customLabel}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                            </div>
                                            <div className="d-flex justify-content-between border-top mt-5 pt-10">
                                                <div className="mr-2">
                                                    <button
                                                        className="btn btn-light-primary font-weight-bold px-9 py-4"
                                                        data-wizard-type="action-prev"
                                                    >
                                                        Precedente
														</button>
                                                </div>
                                                <div>
                                                    <button
                                                        className="btn btn-success font-weight-bold px-9 py-4"
                                                        data-wizard-type="action-submit"
                                                        onClick={handleSubmit}
                                                    >
                                                        Segnala
                                                        </button>
                                                    <button
                                                        className="btn btn-primary font-weight-bold px-9 py-4"
                                                        data-wizard-type="action-next"
                                                    >
                                                        Successivo
														</button>
                                                </div>
                                            </div>
                                        </Form>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};
