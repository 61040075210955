import React from "react";
import { PatientStatusTitles } from "../PatientsUIHelpers";

export const PatientRegistry = ({ patientRegistry, history }) => {
    return (
        <>
            {
                patientRegistry
                    ? <>
                        <div className="d-flex align-items-center">
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center mr-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">Codici Paziente</span>
                                    {
                                        patientRegistry.PatientNumbers.length === 1
                                            ? <span className="font-size-sm">{patientRegistry.PatientNumbers[0]}</span>
                                            : <div>
                                                {
                                                    patientRegistry.PatientNumbers.map((el, index) => {
                                                        let patientCodes = [];
                                                        if (index === 0) {
                                                            patientCodes.push(<span className="font-size-sm" key={'spanPatientNumber' + index}>{el + ", "}</span>)
                                                        } else {
                                                            if (index < patientRegistry.PatientNumbers.length - 1) {
                                                                patientCodes.push(
                                                                    <span className="font-size-sm" key={'spanPatientNumber' + index}><a onClick={() => { history.push("/pazienti/" + el + "/dettaglio") }} key={'aPatientNumber' + index}>{el + ","}</a></span>
                                                                );
                                                            } else {
                                                                patientCodes.push(
                                                                    <span className="font-size-sm" key={'spanPatientNumber' + index}><a onClick={() => { history.push("/pazienti/" + el + "/dettaglio") }} key={'aPatientNumber' + index}>{el}</a></span>
                                                                );
                                                            }
                                                        }
                                                        return patientCodes;
                                                    })
                                                }
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center mr-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">Data di nascita</span>
                                    <span className="font-size-sm">{patientRegistry.BirthDate}</span>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center mr-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">Codice Fiscale</span>
                                    <span className="font-size-sm">{patientRegistry.FiscalCode}</span>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center mr-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">Stato</span>
                                    <span className="font-size-sm">{PatientStatusTitles[patientRegistry.PatientState]}</span>
                                </div>
                            </div>
                        </div>
                        <div className="separator separator-solid my-2" />
                        <div className="d-flex align-items-center">
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center mr-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">Telefono</span>
                                    <span className="font-size-sm">{patientRegistry.Phone}</span>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center mr-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">Email</span>
                                    <span className="font-size-sm">{patientRegistry.Email}</span>
                                </div>
                            </div>
                        </div>
                        <div className="separator separator-solid my-2" />
                        <div className="d-flex align-items-center">
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center mr-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">Distretto</span>
                                    <span className="font-size-sm">{patientRegistry.District}</span>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center mr-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">Caregiver</span>
                                    <span className="font-size-sm">{patientRegistry.CareGiverNominative}</span>
                                </div>
                            </div>
                        </div>
                        <div className="separator separator-solid my-2" />
                        <div className="d-flex align-items-center">
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center mr-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">Indirizzo di Residenza</span>
                                    <span className="font-size-sm">{patientRegistry.ResidenceAddress}</span>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center mr-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">Indirizzo di Domicilio</span>
                                    <span className="font-size-sm">{patientRegistry.HomeAddress}</span>
                                </div>
                            </div>
                        </div>
                        <div className="separator separator-solid my-2" />
                        <div className="d-flex align-items-center">
                            <div className="col-xl-5 col-lg-5 d-flex align-items-center mr-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">Indirizzi Alternativi</span>
                                    {
                                        patientRegistry.Addresses.length > 0
                                            ? patientRegistry.Addresses.map((el, index) => {
                                                return (
                                                    <div className="my-1" key={index}>
                                                        <span className="font-size-sm">{el}</span>
                                                    </div>
                                                )
                                            })
                                            : <span className="font-size-sm">-</span>
                                    }
                                </div>
                            </div>
                        </div>
                        <button
                            className="btn btn-primary mr-2"
                            style={{ float: "right" }}
                            onClick={() => history.push(`/pazienti/${patientRegistry.Id}/segnalazione`)}
                        >
                            Invia segnalazione
                        </button>
                    </>
                    : null
            }
        </>
    );
}