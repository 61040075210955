import React from "react";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../_metronic/_helpers";
import BootstrapTable from "react-bootstrap-table-next";
import { defaultPatientProvisionsSorted } from "./PatientInfoUIHelpers"
import { ProvisionsStateColumnFormatter } from "../../../component/table/column-formatters/StatusColumnFormatter"
import { ProvisionsTypeColumnFormatter } from "../../../component/table/column-formatters/TypeColumnFormatter"
import { tableSortByDate } from "../../../component/table/tableSort";
import "../../../assets/css/table.css";

export function PatientProvisionsTable({ provisions, history }) {

    const columns = [
        {
            dataField: "ProvisionType",
            text: "Tipologia Prestazione",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            formatter: ProvisionsTypeColumnFormatter,
            headerSortingClasses
        },
        {
            dataField: "DocumentNumber",
            text: "Numero Documento",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "District",
            text: "Distretto Sanitario",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "ProvisionDate",
            text: "Data",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortFunc: tableSortByDate,
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "StaffInitials",
            text: "Iniziali Addetto",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "ProvisionState",
            text: "Stato",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            formatter: ProvisionsStateColumnFormatter,
            headerSortingClasses
        },
    ];

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        hideSelectAll: true,
        hideSelectColumn: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            history.push(`/prestazioni/${row.Id}/dettaglio`)
        }
    }

    return (
        <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table-head-custom table-hover table-vertical-center"
            bootstrap4
            keyField="Id"
            data={provisions === null ? [] : provisions}
            columns={columns}
            defaultSorted={defaultPatientProvisionsSorted}
            noDataIndication={"Non sono presenti dati"}
            selectRow={selectRow}
        >
        </BootstrapTable>
    );
}