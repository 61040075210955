import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
//import { initialPatientFilter } from "./PatientsUIHelpers";
import moment from "moment"

const PatientsUIContext = createContext();

export function usePatientsUIContext() {
    return useContext(PatientsUIContext);
}

export function PatientsUIProvider({ patientsUIEvents, children }) {

    let patientsSelectedFilter = JSON.parse(localStorage.getItem('patientsSelectedFilters-...'));

    if (!patientsSelectedFilter) {
        patientsSelectedFilter = {
            filter: {
                //InchargeDate: moment().subtract(1, "month").format('DD/MM/YYYY') + " - " + moment().format('DD/MM/YYYY'),
                SearchText: "",
                Period: moment().subtract(2, "month").format('DD/MM/YYYY') + " - " + moment().format('DD/MM/YYYY'),
                InchargeDate: "",
                DischargeDate: "",
                Status: [],
                Gender: [],
                District: [],
                //Prescriptor: [],
                Prescriptions: []
            },
            // sortOrder: "desc", // asc||desc
            // sortField: "InchargeDate",
        };
    }

    const [queryParams, setQueryParamsBase] = useState(patientsSelectedFilter);
    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }
            return nextQueryParams;
        });
    }, []);

    const value = {
        queryParams,
        setQueryParamsBase,
        setQueryParams,
        openInfoPatientPage: patientsUIEvents.openInfoPatientPage
    };

    return <PatientsUIContext.Provider value={value}>{children}</PatientsUIContext.Provider>;
}