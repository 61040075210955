import React, { useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader
} from "../../../../_metronic/_partials/controls";
import { DevicesTable } from "./DevicesTable";
import { DevicesFilters } from "./DevicesFilters";
import { KTScrollFilters } from "../../../component/scroll/Scroll";

export const DevicesHome = () => {
    const suhbeader = useSubheader();
    suhbeader.setTitle("Dispositivi");

    //inizializzo lo scroll nei filtri
    useEffect(() => {
        KTScrollFilters.init("devicesFilter")
    }, []);

    return (
        <div className="row">
            <div className="col-lg-2">
                <Card className="card-sticky" id="devicesFilter">
                    <CardHeader title="Filtri" className="font-weight-bolder">
                    </CardHeader>
                    <CardBody className="offcanvas-content">
                        <DevicesFilters />
                    </CardBody>
                </Card>
            </div>
            <div className="col-lg-10 card-lg-10">
                <DevicesTable />
            </div>
        </div>
    );
};
