import React, { useMemo, useEffect } from "react";
import { useProvisionsUIContext } from "../ProvisionsUIContext";
import { isEqual } from "lodash";
import { Formik, Form } from "formik";
import { LocaleDatePicker, filterRanges } from "../../../component/datepicker/DateRangePickerConfig";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as actions from "../../../actions/provisions/provisionsActions";

const prepareFilter = (queryParams, values) => {
    const {
        searchText,
        filterByProvisionsDate,
        filterByProvisionDistrict,
        filterByStatus,
        filterByType
    } = values;

    const newQueryParams = { ...queryParams };

    const filter = {
        SearchText: searchText,
        ProvisionsDate: filterByProvisionsDate,
        District: filterByProvisionDistrict,
        Status: filterByStatus,
        Type: filterByType
    };

    newQueryParams.filter = filter;

    localStorage.setItem("provisionsSelectedFilters-...", JSON.stringify(newQueryParams));

    return newQueryParams;
};

export function ProvisionsFilters() {
    const provisionsUIContext = useProvisionsUIContext();
    const provisionsUIProps = useMemo(() => {
        return {
            queryParams: provisionsUIContext.queryParams,
            setQueryParams: provisionsUIContext.setQueryParams,
        };
    }, [provisionsUIContext]);

    const dispatch = useDispatch();

    const applyFilter = (values) => {
        const newQueryParams = prepareFilter(provisionsUIProps.queryParams, values);
        if (!isEqual(newQueryParams, provisionsUIProps.queryParams)) {
            provisionsUIProps.setQueryParams(newQueryParams);
        }
    };

    useEffect(() => {
        dispatch(actions.fetchProvisionsFilters(provisionsUIProps.queryParams.filter.ProvisionsDate));
    }, [provisionsUIProps.queryParams.filter.ProvisionsDate]);

    const { currentState } = useSelector(
        (state) => ({ currentState: state.provisions }),
        shallowEqual
    );

    const {
        filterProvisionsStatus,
        filterProvisionType,
        filterProvisionDistrict
    } = currentState;

    return (
        <Formik
            initialValues={{
                searchText: provisionsUIProps.queryParams.filter.SearchText,
                filterByProvisionsDate: provisionsUIProps.queryParams.filter.ProvisionsDate,
                filterByProvisionDistrict: provisionsUIProps.queryParams.filter.District,
                filterByStatus: provisionsUIProps.queryParams.filter.Status,
                filterByType: provisionsUIProps.queryParams.filter.Type
            }}
            onSubmit={(values, formikBag) => {
                if (values.filterByProvisionsDate !== provisionsUIProps.queryParams.filter.ProvisionsDate) {
                    values = {
                        searchText: "",
                        filterByProvisionsDate: values.filterByProvisionsDate,
                        filterByProvisionDistrict: [],
                        filterByStatus: [],
                        filterByType: []
                    }
                    formikBag.setValues(values);
                }
                applyFilter(values);
            }}
        >
            {({
                values,
                handleSubmit,
                handleBlur,
                setFieldValue,
            }) => (
                    <Form onSubmit={handleSubmit} className="form form-label-right filter">
                        <div className="form-group row">
                            <div className="col-lg-12">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="searchText"
                                    placeholder="Cerca"
                                    onBlur={handleBlur}
                                    value={values.searchText}
                                    onChange={(e) => {
                                        setFieldValue("searchText", e.target.value);
                                        setTimeout(() => {
                                            handleSubmit();
                                        }, 1000);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="separator separator-dashed my-3" />
                        <div className="form-group row">
                            <div className="col-lg-12">
                                <h6 className="font-weight-bolder mb-3">Data Prestazione:</h6>
                                <DateRangePicker
                                    initialSettings={{
                                        ranges: filterRanges,
                                        locale: LocaleDatePicker,
                                        linkedCalendars: false,
                                        startDate: values.filterByProvisionsDate.split('-')[0],
                                        endDate: values.filterByProvisionsDate.split('-')[1]
                                    }}
                                    onApply={(event, picker) => {
                                        setFieldValue("filterByProvisionsDate", picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY'));
                                        handleSubmit();
                                    }}
                                >
                                    <div className="form-control customPickerDisplay">
                                        <i className="fa fa-calendar" />&nbsp;
                                        <span>{values.filterByProvisionsDate}</span>
                                        <div style={{ float: "right" }}>
                                            <i className="fa fa-caret-down" />
                                        </div>
                                    </div>
                                </DateRangePicker>
                            </div>
                        </div>
                        {
                            filterProvisionDistrict && filterProvisionDistrict.length > 0
                                ? <>
                                    <div className="separator separator-dashed my-3" />
                                    <div className="form-group row">
                                        <div className="col-lg-12" style={{ overflow: "hidden" }}>
                                            <h6 className="font-weight-bolder mb-3">Distretti:</h6>
                                            {
                                                filterProvisionDistrict.map((el) => {
                                                    return (
                                                        <div key={"div" + el}>
                                                            <OverlayTrigger
                                                                key={"overlay" + el}
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id={`tooltip-top`}>
                                                                        {el}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <label
                                                                    className="checkbox"
                                                                    key={"label" + el}
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        name="filterByProvisionDistrict"
                                                                        id={el}
                                                                        key={el}
                                                                        value={el}
                                                                        checked={values.filterByProvisionDistrict.includes(el) ? true : false}
                                                                        onChange={(e) => {
                                                                            if (e.target.checked) {
                                                                                values.filterByProvisionDistrict = [
                                                                                    ...values.filterByProvisionDistrict,
                                                                                    el
                                                                                ];
                                                                            } else {
                                                                                values.filterByProvisionDistrict = values.filterByProvisionDistrict.filter((value) => {
                                                                                    return value !== el;
                                                                                });
                                                                            }
                                                                            handleSubmit();
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                    {el}
                                                                    <span />
                                                                </label>
                                                            </OverlayTrigger>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                                : null
                        }
                        {
                            filterProvisionsStatus && filterProvisionsStatus.length > 0
                                ? <>
                                    <div className="separator separator-dashed my-3" />
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <h6 className="font-weight-bolder mb-3">Stato della Prestazione:</h6>
                                            {
                                                filterProvisionsStatus.map((el) => {
                                                    return (
                                                        <div key={"div" + el.label}>
                                                            <label
                                                                className="checkbox"
                                                                key={"label" + el.label}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    name="filterByStatus"
                                                                    id={el.label}
                                                                    key={el.label}
                                                                    value={el.value}
                                                                    checked={values.filterByStatus.filter((e) => { return e === el.value }).length > 0 ? true : false}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            values.filterByStatus = [
                                                                                ...values.filterByStatus,
                                                                                el.value
                                                                            ];
                                                                        } else {
                                                                            values.filterByStatus = values.filterByStatus.filter((value) => {
                                                                                return value !== el.value;
                                                                            });
                                                                        }
                                                                        handleSubmit();
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                />
                                                                {el.label}
                                                                <span />
                                                            </label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                                : null
                        }
                        {
                            filterProvisionType && filterProvisionType.length > 0
                                ? <>
                                    <div className="separator separator-dashed my-3" />
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <h6 className="font-weight-bolder mb-3">Tipologia Prestazione:</h6>
                                            {
                                                filterProvisionType.map((el) => {
                                                    return (
                                                        <div key={"div" + el.label}>
                                                            <label
                                                                className="checkbox"
                                                                key={"label" + el.label}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    name="filterByType"
                                                                    id={el.label}
                                                                    key={el.label}
                                                                    value={el.value}
                                                                    checked={values.filterByType.filter((e) => { return e === el.value }).length > 0 ? true : false}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            values.filterByType = [
                                                                                ...values.filterByType,
                                                                                el.value
                                                                            ];
                                                                        } else {
                                                                            values.filterByType = values.filterByType.filter((value) => {
                                                                                return value !== el.value;
                                                                            });
                                                                        }
                                                                        handleSubmit();
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                />
                                                                {el.label}
                                                                <span />
                                                            </label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                                : null
                        }
                    </Form>
                )}
        </Formik>
    );
}