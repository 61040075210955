import React from "react";
import { CallbackComponent } from "redux-oidc";
import userManager from "../../utils/oidcUserManager";
import { connect, useDispatch } from "react-redux";
import * as actions from "../../modules/Auth/_redux/authActions";

function CallbackPage(props) {

  const dispatch = useDispatch();

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={(user) => {
        dispatch(actions.login(user));
        props.history.push("/");
      }}
      errorCallback={error => {
        props.history.push("/");
        console.error(error);
      }}
    >
      <div>Redirecting...</div>
    </CallbackComponent>
  );
}

export default connect()(CallbackPage);
