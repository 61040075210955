import React from "react"
import { tableSortByDate } from "../../../component/table/tableSort";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../_metronic/_helpers";
import BootstrapTable from "react-bootstrap-table-next";
import { defaultProvisionAccessoriesSorted } from "./ProvisionInfoUIHelpers"
import { AssetsColumnFormatter, /*FotoColumnFormatter,*/ AccessoriesFotoColumnFormatter } from "../../../component/table/column-formatters/ActionsColumnFormatter";

export function ProvisionAccessoriesTable({ provisionAccessories, provisionType }) {
    const columns = [
        {
            isDummyField: true,
            dataField: "Foto",
            text: "",
            align: "center",
            headerAlign: 'center',
            //formatter: FotoColumnFormatter,
            formatter: AccessoriesFotoColumnFormatter, // soluzione momentanea in quanto a volte arrivano foto 'rotte' dal db appena sistemato rimettere quello sopra
        },
        {
            dataField: "AccessoryType",
            text: "Tipologia",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "ItemNumber",
            text: "Codice Articolo",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "Description",
            text: "Descrizione",
            sort: false,
            align: "center",
            headerAlign: 'center',
        },
        {
            dataField: "Serial",
            text: "Seriale",
            sort: false,
            align: "center",
            headerAlign: 'center',
        },
        {
            dataField: "LotNumber",
            text: "Lotto",
            sort: false,
            align: "center",
            headerAlign: 'center',
        }
    ];
    //controllo se è di tipo assistenza
    if (provisionType >= 3 && provisionType <= 6) {
        columns.push(
            {
                dataField: "DeliveryDate",
                text: "Data",
                sort: true,
                align: "center",
                headerAlign: 'center',
                sortFunc: tableSortByDate,
                sortCaret: sortCaret,
                headerSortingClasses,
            },
            {
                isDummyField: true,
                dataField: "",
                text: "Documentazione",
                align: "center",
                headerAlign: 'center',
                formatter: AssetsColumnFormatter,
            }
        );
    } else if (provisionType === 11) {
        columns.push(
            {
                dataField: "DeliveryDate",
                text: "Data Consegna",
                sort: true,
                align: "center",
                headerAlign: 'center',
                sortFunc: tableSortByDate,
                sortCaret: sortCaret,
                headerSortingClasses,
            },
            {
                isDummyField: true,
                dataField: "",
                text: "Documentazione",
                align: "center",
                headerAlign: 'center',
                formatter: AssetsColumnFormatter,
            }
        );
    } else if (provisionType === 13) {
        columns.push(
            {
                dataField: "PickUpDate",
                text: "Data Ritiro",
                sort: true,
                align: "center",
                headerAlign: 'center',
                sortFunc: tableSortByDate,
                sortCaret: sortCaret,
                headerSortingClasses,
            },
            {
                isDummyField: true,
                dataField: "",
                text: "Documentazione",
                align: "center",
                headerAlign: 'center',
                formatter: AssetsColumnFormatter,
            }
        );
    }
    else {
        columns.push(
            {
                dataField: "DeliveryDate",
                text: "Data Consegna",
                sort: true,
                align: "center",
                headerAlign: 'center',
                sortFunc: tableSortByDate,
                sortCaret: sortCaret,
                headerSortingClasses,
            },
            {
                dataField: "PickUpDate",
                text: "Data Ritiro",
                sort: true,
                align: "center",
                headerAlign: 'center',
                sortFunc: tableSortByDate,
                sortCaret: sortCaret,
                headerSortingClasses,
            },
            {
                isDummyField: true,
                dataField: "",
                text: "Documentazione",
                align: "center",
                headerAlign: 'center',
                formatter: AssetsColumnFormatter,
            }
        );
    }

    return (
        <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center"
            bootstrap4
            keyField="Serial"
            data={provisionAccessories}
            columns={columns}
            defaultSorted={defaultProvisionAccessoriesSorted}
            noDataIndication={"Non sono presenti dati"}
        >
        </BootstrapTable>
    );
}