import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "../../../assets/css/table.css";
import { DeviceLogBookActionTableColumnFormatter } from "../../../component/table/column-formatters/ActionsColumnFormatter";
import { useDispatch } from "react-redux";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../_metronic/_helpers";
import { defaultDeviceLogBookSorted } from "./DeviceInfoUIHelpers";
import { LogBookTypeColumnFormatter } from "../../../component/table/column-formatters/TypeColumnFormatter";
import { PatientLinkColumnFormatter, ProvisionLinkColumnFormatter } from "../../../component/table/column-formatters/LinkColumnFormatter";
import { tableSortByDate } from "../../../component/table/tableSort";

export function DeviceLogBookTable({ deviceLogBook, history }) {

    const dispatch = useDispatch();

    const columns = [
        {
            dataField: "Date",
            text: "Data",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
            sortFunc: tableSortByDate,
        },
        {
            dataField: "Type",
            text: "Tipo",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: LogBookTypeColumnFormatter,
        },
        {
            dataField: "District",
            text: "Distretto",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "StaffInitials",
            text: "Iniziali Staff",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "ProvisionType",
            text: "Tipo Prestazione",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: ProvisionLinkColumnFormatter,
        },
        {
            dataField: "PatientFullName",
            text: "Nominativo Paziente",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: PatientLinkColumnFormatter,
        },
        {
            isDummyField: true,
            dataField: "",
            text: "Azioni",
            align: "center",
            headerAlign: 'center',
            formatter: DeviceLogBookActionTableColumnFormatter,
            formatExtraData: {
                history,
                dispatch,
                title: "Invia segnalazione"
            },
            classes: "pr-0",
            headerClasses: "pr-3",
        },
    ];

    return (
        <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table-head-custom table-vertical-center"
            bootstrap4
            keyField="Id"
            data={deviceLogBook}
            columns={columns}
            noDataIndication={"Non sono presenti dati"}
            defaultSorted={defaultDeviceLogBookSorted}
        >
        </BootstrapTable>
    );
}