
export const defaultDevicesTableSorted = [{ dataField: "DeliveryDate", order: "desc" }];

export const DeviceLogBookTypeTitle = ["Sconosciuto", "Attivazione", "Disattivazione", "Assistenza Ordinaria", "Assistenza Programmata", "Assistenza Correttiva", "Assistenza Straordinaria", "Controllo Qualità", "Controllo Funzionalità", "Controllo Taratura", "Verifica Sicurezza Elettrica", "Consegna", "Formazione", "Ritiro", "Intervento Tecnico"];

export function getDeviceLogBookTypeValue(type) {
    let deviceTypeNumber;

    if (type === "UNKNOWN") {
        deviceTypeNumber = 0;
    } else if (type === "ATTIVAZIONE") {
        deviceTypeNumber = 1;
    } else if (type === "DISATTIVAZIONE") {
        deviceTypeNumber = 2;
    } else if (type === "MANUTENZIONEORDINARIA") {
        deviceTypeNumber = 3;
    } else if (type === "MANUTENZIONEPROGRAMMATA") {
        deviceTypeNumber = 4;
    } else if (type === "MANUTENZIONECORRETTIVA") {
        deviceTypeNumber = 5;
    } else if (type === "MANUTENZIONESTRAORDINARIA") {
        deviceTypeNumber = 6;
    } else if (type === "CONTROLLOQUALITA") {
        deviceTypeNumber = 7;
    } else if (type === "CONTROLLOFUNZIONALITA") {
        deviceTypeNumber = 8;
    } else if (type === "CONTROLLOTARATURA") {
        deviceTypeNumber = 9;
    } else if (type === "VERIFICASICUREZZAELETTRICA") {
        deviceTypeNumber = 10;
    } else if (type === "CONSEGNA") {
        deviceTypeNumber = 11;
    } else if (type === "FORMAZIONE") {
        deviceTypeNumber = 12;
    } else if (type === "RITIRO") {
        deviceTypeNumber = 13;
    } else if (type === "INTERVENTOTECNICO") {
        deviceTypeNumber = 14;
    }
    return deviceTypeNumber;
}