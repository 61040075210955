import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../_metronic/_helpers";
import { ProvisionsTypeColumnFormatter } from "../../component/table/column-formatters/TypeColumnFormatter"
import { ProvisionTableModel } from "../../models/provisionTableModel";
import { PaginationModel } from "../../models/paginationModel";
import { getProvisionStateValue, getProvisionTypeValue, ProvisionStatusTitles, ProvisionsTypeTitles, getProvisionTypeTitles } from "../../pages/provisions/ProvisionsUIHelpers";
import { ProvisionModel } from "../../models/provisionModel";
import { PatientModel } from "../../models/patientModel";
import { DeviceModel } from "../../models/deviceModel";
import { AccessorieModel } from "../../models/accessorieModel";
import { ConsumableModel } from "../../models/consumableModel";
import { AssetModel } from "../../models/assetModel";
import { ProvisionMainInfoModel } from "../../models/provisionMainInfoModel";
import { groupBy } from "../../utils/utility";

const initialProvisionsState = {
    pagination: new PaginationModel(),
    provisionMainSection: undefined,
    provisionOtherInfo: undefined,
    provisionsList: [],
    provisionsInProgressList: [],
    provisionsInProgressColumns: [],
    provisionsPlannedList: [],
    provisionsPlannedColumns: [],
    provisionsChart: undefined,
    filterProvisionsStatus: [],
    filterProvisionType: [],
    filterProvisionDistrict: []
};

export const provisionsSlice = createSlice({
    name: "provisions",
    initialState: initialProvisionsState,
    reducers: {
        fetchProvisionsInProgress: (state, action) => {
            try {
                if (action.payload.entities.data.provisionsReviewV2) {
                    let newProvisionsInProgress = {};
                    let columns = [];
                    action.payload.entities.data.provisionsReviewV2.nodes.forEach(
                        (el, index) => {
                            columns.push(
                                {
                                    dataField: el.state,
                                    text: ProvisionStatusTitles[getProvisionStateValue(el.state)],
                                    sort: false,
                                    align: "center",
                                    headerAlign: 'center',
                                }
                            );

                            newProvisionsInProgress[el.state] = el.count;
                            newProvisionsInProgress.Id = index;
                        }
                    );
                    state.provisionsInProgressList = [newProvisionsInProgress];
                    state.provisionsInProgressColumns = columns;
                } else {
                    state.provisionsInProgressList = [];
                    state.provisionsInProgressColumns = [];
                }
            } catch (error) {
                console.error(error);
            }
        },
        fetchProvisionsPlanned: (state, action) => {
            try {
                let plannedProvisions = action.payload.entities.data.provisionsScheduledMatrix.nodes;
                if (plannedProvisions.length > 0) {
                    let newProvisionsPlannedList = [];
                    let columns = [];
                    columns.push(
                        {
                            dataField: "ProvisionType",
                            text: "Tipologia",
                            sort: true,
                            align: "center",
                            headerAlign: 'center',
                            sortCaret: sortCaret,
                            headerSortingClasses,
                            formatter: ProvisionsTypeColumnFormatter,
                        }
                    );
                    plannedProvisions.forEach((el) => {

                        let provisionType = getProvisionTypeValue(el.provisionType);

                        let row = newProvisionsPlannedList.find((element) => { return element.ProvisionType === provisionType });

                        if (!row) {
                            row = {
                                ProvisionType: provisionType
                            };
                            newProvisionsPlannedList.push(row);
                        }
                        row[el.week] = el.count;

                        if (columns.filter(element => { return element.dataField === el.week }).length === 0) {
                            columns.push(
                                {
                                    dataField: el.week,
                                    text: "Settimana " + el.week + " dal " + moment(el.firstWeekDay).format("DD/MM/YYYY") + " al " + moment(el.lastWeekDay).format("DD/MM/YYYY") + "",
                                    sort: true,
                                    align: "center",
                                    headerAlign: 'center',
                                    sortCaret: sortCaret,
                                    headerSortingClasses,
                                }
                            );
                        }

                    });
                    state.provisionsPlannedList = newProvisionsPlannedList;
                    state.provisionsPlannedColumns = columns;
                } else {
                    state.provisionsPlannedList = [];
                    state.provisionsPlannedColumns = [];
                }
            } catch (error) {
                console.error(error);
            }
        },
        provisionsFitlersFetched: (state, action) => {
            try {
                const { entities } = action.payload;
                if (entities.data.provisionTableFilters) {
                    let districts = entities.data.provisionTableFilters.districts;
                    let status = entities.data.provisionTableFilters.provisionStates;
                    let types = entities.data.provisionTableFilters.provisionTypes;

                    let districtsArray = [];

                    if (districts.length > 0) {
                        districts.forEach((e) => {
                            if (e) {
                                districtsArray.push(e);
                            } else {
                                districtsArray.push("-");
                            }
                        });
                    }

                    state.filterProvisionDistrict = districtsArray.sort();


                    let statusArray = [];

                    if (status.length > 0) {
                        status.forEach((el) => {
                            if (el === "PROGRAMMATA") {
                                statusArray.push({ label: ProvisionStatusTitles[0], value: el });
                            } else if (el === "INCORSO") {
                                statusArray.push({ label: ProvisionStatusTitles[1], value: el });
                            } else if (el === "CANCELLATA") {
                                statusArray.push({ label: ProvisionStatusTitles[2], value: el });
                            } else if (el === "SOSPESA") {
                                statusArray.push({ label: ProvisionStatusTitles[3], value: el });
                            } else if (el === "CONCLUSAESITOPOSITIVO") {
                                statusArray.push({ label: ProvisionStatusTitles[4], value: el });
                            } else if (el === "CONCLUSAESITONEGATIVO") {
                                statusArray.push({ label: ProvisionStatusTitles[5], value: el });
                            }
                        });
                    }

                    state.filterProvisionsStatus = statusArray;

                    let typesArray = [];

                    if(types.length>0){
                        types.forEach((el) => {
                            if (el === "UNKNOWN") {
                                typesArray.push({ label: ProvisionsTypeTitles[0], value: el });
                            } else if (el === "ATTIVAZIONE") {
                                typesArray.push({ label: ProvisionsTypeTitles[1], value: el });
                            } else if (el === "DISATTIVAZIONE") {
                                typesArray.push({ label: ProvisionsTypeTitles[2], value: el });
                            } else if (el === "MANUTENZIONEORDINARIA") {
                                typesArray.push({ label: ProvisionsTypeTitles[3], value: el });
                            } else if (el === "MANUTENZIONEPROGRAMMATA") {
                                typesArray.push({ label: ProvisionsTypeTitles[4], value: el });
                            } else if (el === "MANUTENZIONECORRETTIVA") {
                                typesArray.push({ label: ProvisionsTypeTitles[5], value: el });
                            } else if (el === "MANUTENZIONESTRAORDINARIA") {
                                typesArray.push({ label: ProvisionsTypeTitles[6], value: el });
                            } else if (el === "CONTROLLOQUALITA") {
                                typesArray.push({ label: ProvisionsTypeTitles[7], value: el });
                            } else if (el === "CONTROLLOFUNZIONALITA") {
                                typesArray.push({ label: ProvisionsTypeTitles[8], value: el });
                            } else if (el === "CONTROLLOTARATURA") {
                                typesArray.push({ label: ProvisionsTypeTitles[9], value: el });
                            } else if (el === "VERIFICASICUREZZAELETTRICA") {
                                typesArray.push({ label: ProvisionsTypeTitles[10], value: el });
                            } else if (el === "CONSEGNA") {
                                typesArray.push({ label: ProvisionsTypeTitles[11], value: el });
                            } else if (el === "FORMAZIONE") {
                                typesArray.push({ label: ProvisionsTypeTitles[12], value: el });
                            } else if (el === "RITIRO") {
                                typesArray.push({ label: ProvisionsTypeTitles[13], value: el });
                            } else if (el === "INTERVENTOTECNICO") {
                                typesArray.push({ label: ProvisionsTypeTitles[14], value: el });
                            }
                        });
                    }
                    
                    state.filterProvisionType = typesArray;
                } else {
                    state.filterProvisionDistrict = [];
                    state.filterProvisionsStatus = [];
                    state.filterProvisionType = [];
                }
            } catch (error) {
                console.error(error);
            }
        },
        provisionsFetched: (state, action) => {
            try {
                const { entities, holdPagination, isChangedSort } = action.payload;
                if (entities.data.provisionTable) {

                    let newProvisionsList = [];

                    entities.data.provisionTable.nodes.forEach((el, index) => {
                        let model = new ProvisionTableModel();

                        model.Id = el.provisionId.replace('/', '-');
                        model.ProvisionType = getProvisionTypeValue(el.provisionType);
                        model.PatientName = el.patientLastName ? el.patientLastName + " " + el.patientFirstName : "-";
                        model.District = el.district ? el.district : "-";
                        model.DocumentNumber = el.documentNumber ? el.documentNumber : "-";
                        model.PatientId = el.patientId ? el.patientId : "-";
                        model.ProvisionDate = moment(el.provisionDate).format("DD/MM/YYYY");
                        model.StaffInitials = el.staffInitials ? el.staffInitials : "-";
                        model.Status = getProvisionStateValue(el.provisionState);

                        newProvisionsList.push(model);
                    });

                    let pageInfo = entities.data.provisionTable.pageInfo;
                    let newPagination = new PaginationModel();
                    newPagination.EndCursor = pageInfo.endCursor;
                    newPagination.HasNextPage = pageInfo.hasNextPage;

                    if (isChangedSort) {
                        state.provisionsList = newProvisionsList;
                    } else {
                        state.provisionsList = state.provisionsList.length > 0 && holdPagination ? state.provisionsList.concat(newProvisionsList) : newProvisionsList;
                    }
                    state.pagination = newPagination;
                } else {
                    state.provisionsList = [];
                    state.pagination = new PaginationModel();
                }
            } catch (error) {
                console.error(error);
            }
        },
        provisionsForFileFetched: (state, action) => {
            try {
                const { entities, callback } = action.payload;
                let newProvisionsList = [];
                if (entities.data.provisionTable) {
                    entities.data.provisionTable.nodes.forEach((el, index) => {
                        let model = new ProvisionTableModel();

                        model.Id = el.provisionId.replace('/', '-');
                        model.ProvisionType = getProvisionTypeValue(el.provisionType);
                        model.PatientName = el.patientLastName ? el.patientLastName + " " + el.patientFirstName : "-";
                        model.District = el.district ? el.district : "-";
                        model.DocumentNumber = el.documentNumber ? el.documentNumber : "-";
                        model.PatientId = el.patientId ? el.patientId : "-";
                        model.ProvisionDate = moment(el.provisionDate).format("DD/MM/YYYY");
                        model.StaffInitials = el.staffInitials ? el.staffInitials : "-";
                        model.Status = getProvisionStateValue(el.provisionState);

                        newProvisionsList.push(model);
                    });
                }
                callback(newProvisionsList);
            } catch (error) {
                console.error(error);
            }
        },
        provisionsChartFetched: (state, action) => {
            try {
                const { entities } = action.payload;
                if (entities.data.provisionHistoryGraph) {
                    let entitiesChart = entities.data.provisionHistoryGraph.nodes;

                    let provisionsChartArray = [];

                    for (var i = 0; i < entitiesChart.length; i++) {
                        var provisionTypeLabel = getProvisionTypeTitles(entitiesChart[i].provisionType);
                        let existingIndex = provisionsChartArray.findIndex(ec => ec.year === entitiesChart[i].year && ec.monthNumber === entitiesChart[i].monthNumber && ec.provisionType == provisionTypeLabel);

                        if (existingIndex === -1) {
                            // non esiste ancora un elemento con chiave (year, month, provisionType), lo aggiungo e con l'occasione localizzo anche il mese
                            let monthNumber = entitiesChart[i].monthNumber.toString().padStart(4, "0");

                            entitiesChart[i].monthName = moment().set('month', entitiesChart[i].monthNumber - 1).format('MMMM').toUpperCase();
                            entitiesChart[i].YearMonthNumber = entitiesChart[i].year + "" + monthNumber;
                            entitiesChart[i].provisionType = provisionTypeLabel;
                            provisionsChartArray.push(entitiesChart[i]);
                        } else {
                            // esiste già un elemento con chiave (year, month, provisionType), incremento il count
                            provisionsChartArray[existingIndex].count += entitiesChart[i].count;
                        }
                    }

                    provisionsChartArray.sort(function (a, b) {
                        if (a.YearMonthNumber < b.YearMonthNumber) {
                            return 1;
                        } else if (a.YearMonthNumber > b.YearMonthNumber) {
                            return -1;
                        }

                        // Else go to the 2nd item
                        if (a.provisionType < b.provisionType) {
                            return -1;
                        } else if (a.provisionType > b.provisionType) {
                            return 1
                        } else { // nothing to split them
                            return 0;
                        }
                    });

                    //faccio questo perchè il groupby me li ordina in modo inverso non riesco a capire perchè
                    var object = groupBy(provisionsChartArray, "YearMonthNumber");
                    var array = [];
                    for (const key in object) {
                        array.unshift({ data: key, values: object[key] });
                    }

                    state.provisionsChart = array;

                    // let chartArray = []
                    // entities.data.provisionHistoryGraph.nodes.forEach((el) => {
                    //     let chartObject = {};
                    //     chartObject.year = el.year;
                    //     chartObject.monthName = el.monthName;
                    //     chartObject.monthNumber = el.monthNumber;
                    //     chartObject.provisionType = getProvisionTypeTitles(el.provisionType);
                    //     chartObject.provisionState = el.provisionState;
                    //     chartObject.count = el.count;

                    //     let monthNumber = el.monthNumber.toString().padStart(4, "0");

                    //     chartArray.push(chartObject);
                    // });
                    //state.provisionsChart = chartArray;
                } else {
                    state.provisionsChart = undefined;
                }
            } catch (error) {
                console.error(error);
            }
        },
        provisionMainInfoFetched: (state, action) => {
            try {
                const { provisionMainSection } = action.payload;
                if (provisionMainSection.data.provisionTableById) {
                    let provisionMainInfo = provisionMainSection.data.provisionTableById.nodes[0];
                    let model = new ProvisionMainInfoModel();
                    model.Id = provisionMainInfo.provisionId.replace('/', '-');
                    model.DocumentNumber = provisionMainInfo.documentNumber ? provisionMainInfo.documentNumber : "-";
                    model.ProvisionDate = moment(provisionMainInfo.provisionDate).format("DD/MM/YYYY");
                    model.ProvisionState = provisionMainInfo.provisionState;
                    model.ProvisionType = provisionMainInfo.provisionType;
                    model.PatientName = provisionMainInfo.patientLastName + " " + provisionMainInfo.patientFirstName;
                    model.ReasonCode = provisionMainInfo.reasonCode;
                    model.StaffInitials = provisionMainInfo.staffInitials ? provisionMainInfo.staffInitials : "-";
                    model.CustomerId = localStorage.getItem("customerId");
                    state.provisionMainSection = model;
                } else {
                    state.provisionMainSection = undefined;
                }
            } catch (error) {
                console.error(error);
            }
        },
        provisionFetched: (state, action) => {
            try {
                const { provisionOtherInfo } = action.payload;
                if (provisionOtherInfo.data.provisionById) {
                    let provisionInfo = provisionOtherInfo.data.provisionById.nodes[0];
                    let provisionModel = new ProvisionModel();
                    let provisionPatientModel = new PatientModel();
                    provisionModel.Id = provisionInfo.id;
                    provisionModel.CustomerId = provisionInfo.customerId;
                    provisionPatientModel.Id = provisionInfo.patient.id;
                    provisionPatientModel.FirstName = provisionInfo.patient.firstName;
                    provisionPatientModel.LastName = provisionInfo.patient.lastName;
                    provisionPatientModel.PatientNumbers = provisionInfo.patient.patientNumbers.split(',');

                    for (var i = 0; i < provisionPatientModel.PatientNumbers.length; i++) {
                        if (provisionPatientModel.PatientNumbers[i] === provisionPatientModel.Id) {
                            provisionPatientModel.PatientNumbers.splice(i, 1);
                        }
                    }

                    provisionPatientModel.PatientNumbers.unshift(provisionPatientModel.Id);

                    provisionPatientModel.BirthDate = provisionInfo.patient.birthDate ? moment(provisionInfo.patient.birthDate).format("DD/MM/YYYY") : "-";
                    if (provisionInfo.patient.patientState === "UNKNOWN") {
                        provisionPatientModel.PatientState = 0;
                    } else if (provisionInfo.patient.patientState === "INATTIVAZIONE") {
                        provisionPatientModel.PatientState = 1;
                    } else if (provisionInfo.patient.patientState === "ATTIVO") {
                        provisionPatientModel.PatientState = 2;
                    } else if (provisionInfo.patient.patientState === "SOSPESO") {
                        provisionPatientModel.PatientState = 3;
                    } else if (provisionInfo.patient.patientState === "DISATTIVO") {
                        provisionPatientModel.PatientState = 4;
                    } else if (provisionInfo.patient.patientState === "INDISATTIVAZIONE") {
                        provisionPatientModel.PatientState = 5;
                    }
                    provisionPatientModel.FiscalCode = provisionInfo.patient.cF ? provisionInfo.patient.cF : "-";
                    provisionPatientModel.HomeAddress = provisionInfo.patient.homeAddress ? provisionInfo.patient.homeAddress : "-";
                    provisionPatientModel.District = provisionInfo.patient.district ? provisionInfo.patient.district.description : "-";
                    provisionPatientModel.ResidenceAddress = provisionInfo.patient.residenceAddress ? provisionInfo.patient.residenceAddress : "-";

                    provisionModel.Patient = provisionPatientModel;

                    if (provisionInfo.devices && provisionInfo.devices.length > 0) {
                        let devices = [];
                        provisionInfo.devices.forEach(el => {
                            let provisionDeviceModel = new DeviceModel();
                            provisionDeviceModel.Id = el.id;
                            provisionDeviceModel.DeviceType = el.deviceType ? el.deviceType : "-";
                            provisionDeviceModel.BrandAndModel = /*el.brand + " " + el.model*/ el.description;
                            provisionDeviceModel.Serial = el.serial ? el.serial : "-";
                            provisionDeviceModel.TotalHoursOfUse = 0;
                            provisionDeviceModel.LastPatientAssigned = el.lastPatientAssigned ? el.lastPatientAssigned.firstName + " " + el.lastPatientAssigned.lastName : "-";
                            provisionDeviceModel.DeliveryDate = el.provisionDeliveryDate ? moment(el.provisionDeliveryDate).format("DD/MM/YYYY") : "-";
                            provisionDeviceModel.PickUpDate = el.provisionPickupDate ? moment(el.provisionPickupDate).format("DD/MM/YYYY") : "-";
                            provisionDeviceModel.ItemNumber = el.itemCode ? el.itemCode : "-";
                            if (el.assets) {
                                let assets = JSON.parse(el.assets);
                                assets.forEach((el) => {
                                    if (el.AssetType === 0) {
                                        provisionDeviceModel.Photo = el.AssetLink;
                                    } else {
                                        provisionDeviceModel.Assets.push(el);
                                    }
                                });
                            }

                            devices.push(provisionDeviceModel);
                        });
                        provisionModel.Devices = devices;
                    }

                    if (provisionInfo.accessories && provisionInfo.accessories.length > 0) {
                        let accessories = [];
                        provisionInfo.accessories.forEach((el) => {
                            let provisionAccessorieModel = new AccessorieModel();
                            provisionAccessorieModel.AccessoryType = el.accessoryType ? el.accessoryType : "-";
                            provisionAccessorieModel.BrandAndModel = el.brand + " " + el.model;
                            provisionAccessorieModel.Description = el.description;
                            provisionAccessorieModel.Serial = el.serial ? el.serial : "-";
                            provisionAccessorieModel.LotNumber = el.lot ? el.lot : "-";
                            provisionAccessorieModel.DeliveryDate = el.provisionDeliveryDate ? moment(el.provisionDeliveryDate).format("DD/MM/YYYY") : "-";
                            provisionAccessorieModel.PickUpDate = el.provisionPickupDate ? moment(el.provisionPickupDate).format("DD/MM/YYYY") : "-";
                            provisionAccessorieModel.ItemNumber = el.itemCode ? el.itemCode : "-";

                            if (el.assets) {
                                let assets = JSON.parse(el.assets);
                                assets.forEach((el) => {
                                    if (el.AssetType === 0) {
                                        provisionAccessorieModel.Photo = el.AssetLink;
                                    } else {
                                        provisionAccessorieModel.Assets.push(el);
                                    }
                                });
                            }

                            accessories.push(provisionAccessorieModel)
                        });
                        provisionModel.Accessories = accessories
                    }

                    if (provisionInfo.consumables && provisionInfo.consumables.length > 0) {
                        let consumables = [];
                        provisionInfo.consumables.forEach((el) => {
                            let provisionConsumableModel = new ConsumableModel();
                            provisionConsumableModel.ConsumableType = el.consumableType;
                            provisionConsumableModel.BrandAndModel = el.brand + " " + el.model;
                            provisionConsumableModel.Description = el.description;
                            provisionConsumableModel.Serial = el.serial ? el.serial : "-";
                            provisionConsumableModel.LotNumber = el.lot ? el.lot : "-";
                            provisionConsumableModel.Quantity = el.provisionQuantity;
                            provisionConsumableModel.DeliveryDate = el.provisionDeliveryDate ? moment(el.provisionDeliveryDate).format("DD/MM/YYYY") : "-";

                            if (el.assets) {
                                let assets = JSON.parse(el.assets);
                                assets.forEach((el) => {
                                    if (el.AssetType === 0) {
                                        provisionConsumableModel.Photo = el.AssetLink;
                                    } else {
                                        provisionConsumableModel.Assets.push(el);
                                    }
                                });
                            }

                            consumables.push(provisionConsumableModel)
                        });
                        provisionModel.Consumables = consumables
                    }

                    if (provisionInfo.assets) {
                        let assets = JSON.parse(provisionInfo.assets);
                        let newAssetsArray = [];
                        assets.forEach((el, index) => {
                            let provisionAssetModel = new AssetModel();
                            provisionAssetModel.Id = index;
                            provisionAssetModel.AssetTypeDescription = el.AssetTypeDescription;
                            provisionAssetModel.AssetLink = el.AssetLink.replace("'", "");
                            newAssetsArray.push(provisionAssetModel);
                        });
                        provisionModel.Assets = newAssetsArray;
                    }

                    if (provisionInfo.note) {
                        provisionModel.ProvisionNote = provisionInfo.note;
                    }

                    provisionModel.ProvisionType = getProvisionTypeValue(provisionInfo.provisionType);

                    state.provisionOtherInfo = provisionModel;
                } else {
                    state.provisionOtherInfo = undefined
                }
            } catch (error) {
                console.error(error);
            }
        }
    }
});
