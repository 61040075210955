import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import * as actions from "../../../actions/provisions/provisionsActions";
import { useProvisionsUIContext } from "../ProvisionsUIContext"
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../_metronic/_helpers";
import { InfiniteScrollTable } from "../../../component/table/InfiniteScrollTable";
import { defaultProvisionsTableSorted } from "../ProvisionsUIHelpers";
import { tableSortByDate } from "../../../component/table/tableSort";
import { ProvisionsStateColumnFormatter } from "../../../component/table/column-formatters/StatusColumnFormatter";
import { ProvisionsTypeColumnFormatter } from "../../../component/table/column-formatters/TypeColumnFormatter";
import { ExportButton } from "../../../component/exportbutton/ExportButton";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";

export function ProvisionsTable() {

    const provisionsUIContext = useProvisionsUIContext();
    const provisionsUIProps = useMemo(() => {
        return {
            queryParams: provisionsUIContext.queryParams,
            openInfoProvisionPage: provisionsUIContext.openInfoProvisionPage,
        };
    }, [provisionsUIContext]);

    const { currentState } = useSelector(
        (state) => ({ currentState: state.provisions }),
        shallowEqual
    );

    const { provisionsList, pagination } = currentState;

    const [selectedRows, setSelectedRows] = useState([]);
    const [sortTable, setSortTable] = useState(undefined);

    const dispatch = useDispatch();

    useEffect(() => {
        if (sortTable) {
            dispatch(actions.fetchProvisionsTable(provisionsUIProps.queryParams, null, sortTable));
        }
    }, [provisionsUIProps.queryParams, dispatch]);

    const columns = [
        {
            dataField: "ProvisionType",
            text: "Tipologia Prestazione",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: ProvisionsTypeColumnFormatter
        },
        {
            dataField: "DocumentNumber",
            text: "Numero Documento",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "PatientName",
            text: "Nominativo Paziente",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "PatientId",
            text: "Numero Paziente",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "District",
            text: "Distretto Sanitario",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "ProvisionDate",
            text: "Data",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
            sortFunc: tableSortByDate
        },
        {
            dataField: "StaffInitials",
            text: "Iniziali Addetto",
            sort: false,
            align: "center",
            headerAlign: 'center',
        },
        {
            dataField: "Status",
            text: "Stato Prestazione",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: ProvisionsStateColumnFormatter
        }
    ];

    return (
        <Card className="customHeaderPadding">
            <CardHeader title="Lista Prestazioni" className="font-weight-bolder">
                <CardHeaderToolbar>
                    <ExportButton
                        data={selectedRows.length > 0 ? selectedRows : []}
                        loadData={(callback) => dispatch(actions.fetchProvisionsForFile(provisionsUIProps.queryParams, callback))}
                        columns={columns}
                        fileName={"prestazioni"}
                    />
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <InfiniteScrollTable
                    keyField={"Id"}
                    columns={columns}
                    data={provisionsList}
                    defaultSorted={defaultProvisionsTableSorted}
                    pagination={pagination}
                    onSelectRow={provisionsUIProps.openInfoProvisionPage}
                    onSelectCheckBox={(row, isSelect) => {
                        if (isSelect) {
                            setSelectedRows([...selectedRows, row]);
                        } else {
                            setSelectedRows(selectedRows.filter((el) => { return el.Id !== row.Id }));
                        }
                    }}
                    hoverEnable={true}
                    loadData={(event, tableInfo) => {
                        if (tableInfo) {
                            setSortTable({ dataField: tableInfo.sortField, order: tableInfo.sortOrder });
                            dispatch(actions.fetchProvisionsTable(provisionsUIProps.queryParams, pagination, { dataField: tableInfo.sortField, order: tableInfo.sortOrder }, true));
                        } else {
                            dispatch(actions.fetchProvisionsTable(provisionsUIProps.queryParams, pagination, sortTable, false));
                        }
                    }}
                    noData={"Non sono presenti dati"}
                />
            </CardBody>
        </Card>
    );
}