import axios from "axios";
import { path } from "../../utils/constant";

export function getPatientById(patientId) {
  return axios.post(
      path,
    {
      query: 'query{patientById(patientId: "' + patientId + '"){nodes{id patientNumbers firstName lastName fullName customerId birthDate gender cF patientState regionalCode district{description}residenceAddress homeAddress addresses{address city zipCode province startDate endDate} phone careGiver{firstName lastName}prescriptionDetails{id code description prescriptionDate startDate endDate note prescriptor{firstName lastName}}provisions(first:60 order_by:{provisionDate:DESC}){nodes{id documentNumber provisionState provisionType provisionDate staff{initials}}}devices{id itemCode deviceType brand serial model description patientDeliveryDate patientPickupDate assets}accessories{lot itemCode description accessoryType serial patientDeliveryDate patientPickupDate assets}consumables{lot consumableType brand model description serial patientQuantity patientDeliveryDate assets}medicinals{brand deliveryDate description lot name serial}}}}'
    });
}

export function getPatientInvoices(patientId) {
  return axios.post(
      path,
    {
      query: 'query{patientTherapyDaysAmounts(patientId:"' + patientId + '" startDate:"2000-01-01T00:00:00Z"){nodes{dischargeDate inchargeDate year monthName monthNumber prescriptionName therapyDays totalAmount}}}'
    }
  );
}

export function findPatients(queryParams) {
  return axios.post(
      path,
    {
      query: 'query{patientsReview(' + queryParams + '){nodes{id firstName lastName fullName cF gender districtName patientState latestPatientStateInDateRange prescriptionDetailDescription prescriptionDetailId prescriptionDetailStartDate prescriptionDetailEndDate therapyDaysArray provisionsCount}pageInfo{endCursor hasNextPage} totalCount}}'
    });
}

export function findPatientsChart(queryParams) {
  return axios.post(
      path,
    {
      query: 'query{patientsReviewDaysGraphV2(' + queryParams + '){nodes{prescriptionName year monthNumber count}}}'
    });
}

export function findPatientChart(queryParams) {
  return axios.post(
      path,
    {
      query: 'query{patientTherapyDaysGraph(' + queryParams + '){nodes{prescriptionName year monthName monthNumber count}}}'
    });
}

export function findPatientProvisionsNear(patientId) {
  return axios.post(
      path,
    {
      query: 'query{patientsProvisionsNear(patientId: "' + patientId + '"){lastProvision{provisionType provisionDate provisionState}nextProvision{provisionType provisionDate}}}'
    });
}

export function findPatientsFilters(queryParams) {
  return axios.post(
      path,
    {
      query: 'query {patientsReviewFilters(' + queryParams + ') {districts patientGenders patientStates prescriptions}}'
    });
}
