export class ProvisionTableModel {
    constructor() {
        this.ProvisionType = 0;
        this.DocumentNumber = "";
        this.PatientName = "";
        this.PatientId = "";
        this.District = "";
        this.ProvisionDate = "";
        this.StaffInitials = "";
        this.Status = 0;
        this.Id = "";
        this.PrescriptorFullName = "";
    }
}