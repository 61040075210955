
export const ProvisionStatusCssClasses = ["info", "success", "warning", "secondary", "primary", "danger"];
export const ProvisionStatusTitles = ["Programmata", "In corso", "Cancellata", "Sospesa", "Conclusa con esito positivo", "Conclusa con esito negativo"];
export const ProvisionsTypeTitles = ["Sconosciuta", "Attivazione", "Disattivazione", "Assistenza Ordinaria", "Assistenza Programmata", "Assistenza Correttiva", "Assistenza Straordinaria", "Controllo Qualità", "Controllo Funzionalità", "Controllo Taratura", "Verifica Sicurezza Elettrica", "Consegna", "Formazione", "Ritiro", "Intervento Tecnico"];

export const defaultProvisionsPlannedSorted = [{ dataField: "ProvisionType", order: "desc" }];

export const defaultProvisionsTableSorted = [{ dataField: "ProvisionDate", order: "desc" }];

export function getProvisionTypeValue(provisionType) {
    let provisionTypeNumber;

    if (provisionType === "UNKNOWN") {
        provisionTypeNumber = 0;
    } else if (provisionType === "ATTIVAZIONE") {
        provisionTypeNumber = 1;
    } else if (provisionType === "DISATTIVAZIONE") {
        provisionTypeNumber = 2;
    } else if (provisionType === "MANUTENZIONEORDINARIA") {
        provisionTypeNumber = 3;
    } else if (provisionType === "MANUTENZIONEPROGRAMMATA") {
        provisionTypeNumber = 4;
    } else if (provisionType === "MANUTENZIONECORRETTIVA") {
        provisionTypeNumber = 5;
    } else if (provisionType === "MANUTENZIONESTRAORDINARIA") {
        provisionTypeNumber = 6;
    } else if (provisionType === "CONTROLLOQUALITA") {
        provisionTypeNumber = 7;
    } else if (provisionType === "CONTROLLOFUNZIONALITA") {
        provisionTypeNumber = 8;
    } else if (provisionType === "CONTROLLOTARATURA") {
        provisionTypeNumber = 9;
    } else if (provisionType === "VERIFICASICUREZZAELETTRICA") {
        provisionTypeNumber = 10;
    } else if (provisionType === "CONSEGNA") {
        provisionTypeNumber = 11;
    } else if (provisionType === "FORMAZIONE") {
        provisionTypeNumber = 12;
    } else if (provisionType === "RITIRO") {
        provisionTypeNumber = 13;
    } else if (provisionType === "INTERVENTOTECNICO") {
        provisionTypeNumber = 14;
    }
    return provisionTypeNumber;
}

export function getProvisionTypeKey(provisionType) {
    let provisionTypeNumber;

    if (provisionType === 0) {
        provisionTypeNumber = "UNKNOWN";
    } else if (provisionType === 1) {
        provisionTypeNumber = "ATTIVAZIONE";
    } else if (provisionType === 2) {
        provisionTypeNumber = "DISATTIVAZIONE";
    } else if (provisionType === 3) {
        provisionTypeNumber = "MANUTENZIONEORDINARIA";
    } else if (provisionType === 4) {
        provisionTypeNumber = "MANUTENZIONEPROGRAMMATA";
    } else if (provisionType === 5) {
        provisionTypeNumber = "MANUTENZIONECORRETTIVA";
    } else if (provisionType === 6) {
        provisionTypeNumber = "MANUTENZIONESTRAORDINARIA";
    } else if (provisionType === 7) {
        provisionTypeNumber = "CONTROLLOQUALITA";
    } else if (provisionType === 8) {
        provisionTypeNumber = "CONTROLLOFUNZIONALITA";
    } else if (provisionType === 9) {
        provisionTypeNumber = "CONTROLLOTARATURA";
    } else if (provisionType === 10) {
        provisionTypeNumber = "VERIFICASICUREZZAELETTRICA";
    } else if (provisionType === 11) {
        provisionTypeNumber = "CONSEGNA";
    } else if (provisionType === 12) {
        provisionTypeNumber = "FORMAZIONE";
    } else if (provisionType === 13) {
        provisionTypeNumber = "RITIRO";
    } else if (provisionType === "INTERVENTOTECNICO") {
        provisionTypeNumber = 14;
    }
    return provisionTypeNumber;
}

export function getProvisionTypeTitles(provisionType) {
    let provisionTypeTitle = "";

    if (provisionType === "UNKNOWN") {
        provisionTypeTitle = ProvisionsTypeTitles[0];
    } else if (provisionType === "ATTIVAZIONE") {
        provisionTypeTitle = ProvisionsTypeTitles[1];
    } else if (provisionType === "DISATTIVAZIONE") {
        provisionTypeTitle = ProvisionsTypeTitles[2];
    } else if (provisionType === "MANUTENZIONEORDINARIA") {
        provisionTypeTitle = ProvisionsTypeTitles[3];
    } else if (provisionType === "MANUTENZIONEPROGRAMMATA") {
        provisionTypeTitle = ProvisionsTypeTitles[4];
    } else if (provisionType === "MANUTENZIONECORRETTIVA") {
        provisionTypeTitle = ProvisionsTypeTitles[5];
    } else if (provisionType === "MANUTENZIONESTRAORDINARIA") {
        provisionTypeTitle = ProvisionsTypeTitles[6];
    } else if (provisionType === "CONTROLLOQUALITA") {
        provisionTypeTitle = ProvisionsTypeTitles[7];
    } else if (provisionType === "CONTROLLOFUNZIONALITA") {
        provisionTypeTitle = ProvisionsTypeTitles[8];
    } else if (provisionType === "CONTROLLOTARATURA") {
        provisionTypeTitle = ProvisionsTypeTitles[9];
    } else if (provisionType === "VERIFICASICUREZZAELETTRICA") {
        provisionTypeTitle = ProvisionsTypeTitles[10];
    } else if (provisionType === "CONSEGNA") {
        provisionTypeTitle = ProvisionsTypeTitles[11];
    } else if (provisionType === "FORMAZIONE") {
        provisionTypeTitle = ProvisionsTypeTitles[12];
    } else if (provisionType === "RITIRO") {
        provisionTypeTitle = ProvisionsTypeTitles[13];
    } else if (provisionType === "INTERVENTOTECNICO") {
        provisionTypeTitle = ProvisionsTypeTitles[14];
    }
    return provisionTypeTitle;
}

export function getProvisionStateValue(provisionState) {
    let provisionStateNumber;

    if (provisionState === "PROGRAMMATA") {
        provisionStateNumber = 0;
    } else if (provisionState === "INCORSO") {
        provisionStateNumber = 1;
    } else if (provisionState === "CANCELLATA") {
        provisionStateNumber = 2;
    } else if (provisionState === "SOSPESA") {
        provisionStateNumber = 3;
    } else if (provisionState === "CONCLUSAESITOPOSITIVO") {
        provisionStateNumber = 4;
    } else if (provisionState === "CONCLUSAESITONEGATIVO") {
        provisionStateNumber = 5;
    }

    return provisionStateNumber;
}

export function getProvisionStateKey(provisionState) {
    let provisionStateKey;

    if (provisionState === 0) {
        provisionStateKey = "PROGRAMMATA";
    } else if (provisionState === 1) {
        provisionStateKey = "INCORSO";
    } else if (provisionState === 2) {
        provisionStateKey = "CANCELLATA";
    } else if (provisionState === 3) {
        provisionStateKey = "SOSPESA";
    } else if (provisionState === 4) {
        provisionStateKey = "CONCLUSAESITOPOSITIVO";
    } else if (provisionState === 5) {
        provisionStateKey = "CONCLUSAESITONEGATIVO";
    }

    return provisionStateKey;
}

export function getProvisionStateTitles(provisionState) {
    let provisionStateTitle = "";

    if (provisionState === "PROGRAMMATA") {
        provisionStateTitle = ProvisionStatusTitles[0];
    } else if (provisionState === "INCORSO") {
        provisionStateTitle = ProvisionStatusTitles[1];
    } else if (provisionState === "CANCELLATA") {
        provisionStateTitle = ProvisionStatusTitles[2];
    } else if (provisionState === "SOSPESA") {
        provisionStateTitle = ProvisionStatusTitles[3];
    } else if (provisionState === "CONCLUSAESITOPOSITIVO") {
        provisionStateTitle = ProvisionStatusTitles[4];
    } else if (provisionState === "CONCLUSAESITONEGATIVO") {
        provisionStateTitle = ProvisionStatusTitles[5];
    }

    return provisionStateTitle;
}