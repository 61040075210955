import React, { useState } from "react";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../_metronic/_helpers";
import BootstrapTable from "react-bootstrap-table-next";
import { defaultPatientInvoicesSorted } from "./PatientInfoUIHelpers"
import { PatientInvoiceActionTableColumnFormatter } from "../../../component/table/column-formatters/ActionsColumnFormatter";
import { tableSortByDate } from "../../../component/table/tableSort";
import { useDispatch } from "react-redux";
import "../../../assets/css/table.css";

export function PatientInvoicesTable({ patientInvoicesList, id, history }) {

    const [stateTable, setStateTable] = useState({ field: null, order: null });
    const [index, setIndex] = useState(0);

    const dispatch = useDispatch();

    const handleCustomSort = (field, order) => {
        if (index !== 0) {
            if (order === "desc") {
                order = "asc";
            }
        }
        setStateTable(
            {
                field: 'MonthNumber',
                order: order === "asc" ? "desc" : "asc"
            }
        );
        setIndex(index === 0 ? index + 1 : index - 1);
    }

    const columns = [
        {
            dataField: "MonthYear",
            text: "Mese/Anno",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
            onSort: handleCustomSort
        },
        {
            dataField: "MonthNumber",
            text: "",
            sort: true,
            hidden: true
        },
        {
            dataField: "PrescriptionName",
            text: "Prescrizione",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "InchargeDate",
            text: "Data Presa Incarico",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
            sortFunc: tableSortByDate,
        },
        {
            dataField: "DischargeDate",
            text: "Data Dismissione",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
            sortFunc: tableSortByDate,
        },
        {
            dataField: "TherapyDays",
            text: "Giorni Terapia Nel Mese",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "TotalAmount",
            text: "Costo",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            isDummyField: true,
            dataField: "",
            text: "Azioni",
            align: "center",
            headerAlign: 'center',
            formatter: PatientInvoiceActionTableColumnFormatter,
            formatExtraData: {
                history,
                dispatch,
                link: `/pazienti/${id}/segnalazione-fattura`,
                title: "Invia segnalazione"
            },
            classes: "pr-0",
            headerClasses: "pr-3",
        },
    ];

    return (
        <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table-head-custom table-vertical-center"
            bootstrap4
            keyField="Id"
            data={patientInvoicesList}
            columns={columns}
            defaultSorted={defaultPatientInvoicesSorted}
            noDataIndication={"Non sono presenti dati"}
            sort={{
                dataField: stateTable.field,
                order: stateTable.order
            }}
        >
        </BootstrapTable>
    );
}