import React, { useState, useEffect } from "react";
import { Tab, Nav } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../app/actions/users/usersActions";

export function QuickPanel() {
  const [selectedTab, setSelectedTab] = useState("Organizations");

  const setTab = _tabName => {
    setSelectedTab(_tabName);
  };

  const dispatch = useDispatch();

  const { currentState, user } = useSelector((state) => (
    {
      currentState: state.users,
      user: state.auth.user
    }
  ), shallowEqual);

  useEffect(() => {
    dispatch(actions.fetchCustomers(user));
  }, [dispatch]);

  const { customersList } = currentState;

  return (
    <div id="kt_quick_panel" className="offcanvas offcanvas-right pt-5 pb-10">
      <Tab.Container
        defaultActiveKey={selectedTab}
      >
        <div
          className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
          <Nav
            onSelect={setTab}
            as="ul"
            role="tablist"
            className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
          >
            <Nav.Item className="nav-item" as="li">
              <Nav.Link
                eventKey="Organizations"
                className={`nav-link ${selectedTab === "Organizations" ? "active" : ""
                  }`}
              >
                Organizzazioni
                </Nav.Link>
            </Nav.Item>
          </Nav>

          <div className="offcanvas-close mt-n1 pr-5" style={{ position: "absolute", top: "15px", right: "10px" }}>
            <a
              href="#"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              id="kt_quick_panel_close"
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </a>
          </div>
        </div>
        <div className="offcanvas-content px-10">
          <div className="tab-content">
            <div
              id="kt_quick_panel_notifications"
              role="tabpanel"
              className={`tab-pane fade pt-2 pr-5 mr-n5 scroll ps ${selectedTab === "Organizations" ? "active show" : ""}`}
            >
              <div className="navi navi-icon-circle navi-spacer-x-0">
                {
                  customersList.length > 0
                    ? customersList.map((element) => {
                      return (
                        <a id="kt_quick_panel_a" href="#" className="navi-item" key={element.CustomerName} onClick={() => {
                          dispatch(actions.setCustomerId(element));
                          window.location.href = "/"
                        }}>
                          <div className="navi-link rounded">
                            <div className="symbol symbol-50 mr-3">
                              <div className="symbol-label">
                                <i className="flaticon-bell text-success icon-lg"></i>
                              </div>
                            </div>
                            <div className="navi-text">
                              <div className="font-weight-bold font-size-lg">
                                {element.CustomerName}
                              </div>
                              <div className="text-muted">{element.CustomerAddress}</div>
                            </div>
                          </div>
                        </a>
                      )
                    })
                    : null
                }
              </div>
            </div>
          </div>
        </div>
      </Tab.Container>
    </div>
  );
}
