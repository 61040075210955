import React from "react";
import { getProvisionStateTitles, getProvisionTypeTitles } from "../ProvisionsUIHelpers";

export const ProvisionMainInfo = ({ provisionMainSection, history }) => {
    return (
        <>
            {
                provisionMainSection
                    ? <>
                        <div className="d-flex">
                            <div className="flex-shrink-0 mr-1" />
                            <div className="flex-grow-1">
                                <div className="d-flex align-items-center justify-content-between mt-2">
                                    <div className="mr-3">
                                        <h5 className="d-flex align-items-center text-dark font-weight-bold mr-3">
                                            Tipologia Prestazione: {getProvisionTypeTitles(provisionMainSection.ProvisionType)}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="col-xl-2 col-lg-2 d-flex align-items-center mr-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">Numero Documento</span>
                                    <span className="font-size-sm">{provisionMainSection.DocumentNumber}</span>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 d-flex align-items-center mr-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">Data</span>
                                    <span className="font-size-sm">{provisionMainSection.ProvisionDate}</span>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 d-flex align-items-center mr-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">Stato</span>
                                    <span className="font-size-sm">{getProvisionStateTitles(provisionMainSection.ProvisionState)}</span>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 d-flex align-items-center mr-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">Iniziali addetto</span>
                                    <span className="font-size-sm">{provisionMainSection.StaffInitials}</span>
                                </div>
                            </div>
                            {
                                provisionMainSection.ProvisionType === "CONSEGNA" || provisionMainSection.ProvisionType === "RITIRO"
                                    ? <>
                                        <div className="col-xl-2 col-lg-2 d-flex align-items-center mr-5 my-1">
                                            <div className="d-flex flex-column">
                                                <span className="font-size-h6">Causale</span>
                                                <span className="font-size-sm">{provisionMainSection.ReasonCode}</span>
                                            </div>
                                        </div>
                                    </>
                                    : null
                            }

                        </div>
                        <div className="row">
                            <label className="col-xl-3" />
                            <div className="col-lg-9 col-xl-6">
                                <h5 className="font-weight-bold mt-10 mb-6" />
                            </div>
                        </div>
                        <button
                            className="btn btn-primary mr-2"
                            style={{ float: "right" }}
                            onClick={() => history.push(`/prestazioni/${provisionMainSection.Id}/segnalazione`)}
                        >
                            Invia segnalazione
                        </button>
                    </>
                    : null
            }
        </>
    );
}