export class ServiceIndicatorModel {
    constructor() {
        this.Id = 0;
        //this.MonthYear = "";
        this.MonthNumber = 0;
        this.DistrictName = "";
        this.PatientsActive = 0;
        this.PatientsActivating = 0;
        this.PatientsDisactivating = 0;
        this.ProvisionsPositive = 0;
        this.ProvisionsSuspended = 0;
        this.ProvisionsNegative = 0;
        //this.ProvisionsScheduled = 0;
    }
}