//export const path = "https://localhost:44358/api";
//export const path = "https://ae-linde-lsi-api.azurewebsites.net/api";
//export const emailPath = "https://ae-linde-lsi-api.azurewebsites.net/api/mails/sendmails";

// export const path = "https://lsiapi.lindemedicale.net/api";
// export const emailPath = "https://lsiapi.lindemedicale.net/api/mails/sendmails";

export const path = process.env.REACT_APP_URL;
export const emailPath = process.env.REACT_APP_EMAIL_PATH;

export const companies = [
    {
        CustomerName: "ASL ROMA 5",
        CustomerAddress: "Via Acquaregna nn.1/15",
        CustomerId: "M002337"
    },
    {
        CustomerName: "ASL NOVARA",
        CustomerAddress: "Viale Roma, 7",
        CustomerId: "M013991"
    },
    {
        CustomerName: "ASST GRANDE OSPEDALE METROPOLITANO NIGUARDA",
        CustomerAddress: "Piazza Ospedale Maggiore, 3",
        CustomerId: "M034787"
    },
    {
        CustomerName: "ASL BARI",
        CustomerAddress: "",
        CustomerId: "M003158"
    },
    {
        CustomerName: "ASST MONZA",
        CustomerAddress: "",
        CustomerId: "M029141"
    },
    {
        CustomerName: "ASST BRIANZA",
        CustomerAddress: "",
        CustomerId: "M050347"
    },
    {
        CustomerName: "ASST BRIANZA (old)",
        CustomerAddress: "",
        CustomerId: "M043360"
    },
    {
        CustomerName: "ASST LECCO",
        CustomerAddress: "",
        CustomerId: "M032086"
    },
    {
        CustomerName: "ASL TERAMO",
        CustomerAddress: "",
        CustomerId: "M000757"
    },
    {
        CustomerName: "ASST OVEST MILANESE",
        CustomerAddress: "",
        CustomerId: "M029087"
    },
    {
        CustomerName: "ASST PAPA GIOVANNI XXIII",
        CustomerAddress: "",
        CustomerId: "M031182"
    },
    {
        CustomerName: "FOND. IRCCS SAN GERARDO DEI TINTORI",
        CustomerAddress: "",
        CustomerId: "M049505"
    },
    {
        CustomerName: "ASL FOGGIA",
        CustomerAddress: "",
        CustomerId: "M010147"
    },
    {
        CustomerName: "ASL CASERTA",
        CustomerAddress: "",
        CustomerId: "M012897"
    },
    {
        CustomerName: "ASST PAVIA",
        CustomerAddress: "",
        CustomerId: "M029062"
    },
    {
        CustomerName: "ASST PAVIA (LOX)",
        CustomerAddress: "",
        CustomerId: "M037984"
    },
    {
        CustomerName: "AZIENDA ULSS N. 9 SCALIGERA",
        CustomerAddress: "",
        CustomerId: "M031259"
    },
    {
        CustomerName: "AZIENDA ULSS N. 9 SCALIGERA (LOX)",
        CustomerAddress: "",
        CustomerId: "M031523"
    },
    {
        CustomerName: "ASL CASERTA",
        CustomerAddress: "",
        CustomerId: "M012897"
    },
    {
        CustomerName: "AZIENDA SANITARIA LOCALE LE",
        CustomerAddress: "",
        CustomerId: "M010153"
    },
    {
        CustomerName: "AZ.SAN.PROV.LE 6 PALERMO",
        CustomerAddress: "",
        CustomerId: "M013941"
    },
    {
        CustomerName: "A.S.P. PALERMO - Uff.122",
        CustomerAddress: "",
        CustomerId: "M034922"
    },
    {
        CustomerName: "AZIENDA USL RIETI",
        CustomerAddress: "",
        CustomerId: "M002826"
    },
    {
        CustomerName: "ASL PESCARA",
        CustomerAddress: "",
        CustomerId: "M012428"
    }
];
