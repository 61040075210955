import axios from "axios";
import { emailPath } from "../../utils/constant";

export function editEntity(object) {
  return axios.post(
    emailPath,
    JSON.stringify(object),
    {
      headers: { 'Content-Type': 'application/json' }
    });
}
