import React from "react";
import { useHistory } from "react-router-dom";
import { PatientStatusTitles } from "../../patients/PatientsUIHelpers";

export const ProvisionPatientInfo = ({ provisionInfo }) => {

    let patientRegistry = provisionInfo ? provisionInfo.Patient : undefined;

    const history = useHistory();

    return (
        <>
            {
                provisionInfo
                    ? <>
                        <div className="d-flex">
                            <div className="flex-shrink-0 mr-1" />
                            <div className="flex-grow-1">
                                <div className="d-flex align-items-center justify-content-between mt-2">
                                    <div className="mr-3">
                                        {
                                            patientRegistry.PatientNumbers.length > 1
                                                ? <h5 className="d-flex align-items-center text-dark font-weight-bold mr-3">
                                                    Nominativo: {patientRegistry.LastName + " " + patientRegistry.FirstName + " - " + patientRegistry.Id}
                                                </h5>
                                                : <h5 className="d-flex align-items-center text-dark font-weight-bold mr-3">
                                                    Nominativo: {patientRegistry.LastName + " " + patientRegistry.FirstName}
                                                </h5>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center mr-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">Codici Paziente</span>
                                    {
                                        patientRegistry.PatientNumbers.length === 1
                                            ? <span className="font-size-sm"><a onClick={() => { history.push("/pazienti/" + patientRegistry.PatientNumbers[0] + "/dettaglio") }} >{patientRegistry.PatientNumbers[0]}</a></span>
                                            : <div>
                                                {
                                                    patientRegistry.PatientNumbers.map((el, index) => {
                                                        let patientCodes = [];
                                                        if (index < patientRegistry.PatientNumbers.length - 1) {
                                                            patientCodes.push(
                                                                <span className="font-size-sm" key={index}><a onClick={() => { history.push("/pazienti/" + el + "/dettaglio") }} >{el + ","}</a></span>
                                                            );
                                                        } else {
                                                            patientCodes.push(
                                                                <span className="font-size-sm" key={index}><a onClick={() => { history.push("/pazienti/" + el + "/dettaglio") }}>{el}</a></span>
                                                            );
                                                        }
                                                        return patientCodes;
                                                    })
                                                }
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center mr-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">Data di nascita</span>
                                    <span className="font-size-sm">{patientRegistry.BirthDate}</span>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center mr-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">Codice Fiscale</span>
                                    <span className="font-size-sm">{patientRegistry.FiscalCode}</span>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center mr-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">Stato</span>
                                    <span className="font-size-sm">{PatientStatusTitles[patientRegistry.PatientState]}</span>
                                </div>
                            </div>
                        </div>
                        <div className="separator separator-solid my-2" />
                        <div className="d-flex align-items-center">
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center flex-lg-fill mr-5 my-1">
                                <div className="d-flex flex-column text-dark-75">
                                    <span className="font-size-h6">Indirizzo di Residenza</span>
                                    <span className="font-size-sm">{patientRegistry.ResidenceAddress}</span>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center flex-lg-fill mr-5 my-1">
                                <div className="d-flex flex-column text-dark-75">
                                    <span className="font-size-h6">Indirizzo di Domicilio</span>
                                    <span className="font-size-sm">{patientRegistry.HomeAddress}</span>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center flex-lg-fill mr-5 my-1">
                                <div className="d-flex flex-column text-dark-75">
                                    <span className="font-size-h6">Distretto</span>
                                    <span className="font-size-sm">{patientRegistry.District}</span>
                                </div>
                            </div>
                        </div>
                    </>
                    : null
            }
        </>
    );
}