import React from "react";
import { DeviceInfoUIProvider } from "./DeviceInfoUIContext";
import { DeviceInfoHome } from "./DeviceInfoHome";

export const DeviceInfoPage = ({ history, id }) => {
    return (
        <DeviceInfoUIProvider>
            <DeviceInfoHome history={history} id={id} />
        </DeviceInfoUIProvider>
    );
}