import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { PatientsPage } from "./pages/patients/PatientsHome/PatientsPage";
import { ProvisionsPage } from "./pages/provisions/ProvisionHome/ProvisionsPage";
import { PatientEditHome } from "./pages/patients/PatientEdit/PatientEditHome";
import { PatientPrescriptionEditHome } from "./pages/patients/PatientEdit/PatientPrescriptionEditHome";
import { PatientInvoiceEditHome } from "./pages/patients/PatientEdit/PatientInvoiceEditHome";
import { DevicesPage } from "./pages/devices/DeviceHome/DevicesPage";
//import { StaffsPage } from "./pages/staffs/StaffsHome/StaffsPage";
import { PatientsInfoPage } from "./pages/patients/PatientInfo/PatientInfoPage";
import { ProgressSpinner } from "./component/spinner/ProgressSpinner"
import { ProvisionsInfoPage } from "./pages/provisions/ProvisionInfo/ProvisionInfoPage";
import { DeviceInfoPage } from "./pages/devices/DeviceInfo/DeviceInfoPage";
import { ProvisionEditHome } from "./pages/provisions/ProvisionEdit/ProvisionEditHome"
import { ProvisionAssetsEditHome } from "./pages/provisions/ProvisionEdit/ProvisionAssetsEditHome";
import { DeviceLogBookEditHome } from "./pages/devices/DeviceEdit/DeviceLogBookEditHome";
import { DeviceLastPatientAssignedEditHome } from "./pages/devices/DeviceEdit/DeviceLastPatientAssignedEditHome";
import { DeviceEditHome } from "./pages/devices/DeviceEdit/DeviceEditHome";
// import { InvoicesPage } from "./pages/invoices/invoicesHome/InvoicesPage";
// import { CostsPage } from "./pages/invoices/costsHome/CostsPage";
// import { ServiceIndicatorsPage } from "./pages/serviceIndicators/ServiceIndicatorsHome/ServiceIndicatorsPage";
// import { AccountEditHome } from "./pages/User/AccountEditHome";
import "./assets/css/card.css";

export default function BasePage() {
  const { spinnerProperties } = useSelector(
    (state) => ({
      spinnerProperties: state.spinner
    }),
    shallowEqual
  );

  return (
    <Suspense fallback={<LayoutSplashScreen />}>

      <ProgressSpinner visible={spinnerProperties.show} />

      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/pazienti" />
        }
        <Route path="/pazienti/:id/segnalazione">
          {({ history, match }) => (
            <PatientEditHome
              id={match && match.params.id}
            />
          )}
        </Route>
        <Route path="/pazienti/:id/segnalazione-prescrizione">
          {({ history, match }) => (
            <PatientPrescriptionEditHome
              id={match && match.params.id}
            />
          )}
        </Route>
        <Route path="/pazienti/:id/segnalazione-fattura">
          {({ history, match }) => (
            <PatientInvoiceEditHome
              id={match && match.params.id}
            />
          )}
        </Route>
        <Route path="/pazienti/:id/dettaglio">
          {({ history, match }) => (
            <PatientsInfoPage
              id={match && match.params.id}
              history={history}
            />
          )}
        </Route>
        <Route path="/prestazioni/:id/dettaglio">
          {({ history, match }) => (
            <ProvisionsInfoPage
              id={match && match.params.id}
              history={history}
            />
          )}
        </Route>
        <Route path="/prestazioni/:id/segnalazione">
          {({ history, match }) => (
            <ProvisionEditHome
              id={match && match.params.id}
            />
          )}
        </Route>
        <Route path="/prestazioni/:id/segnalazione-informazioni-dettaglio">
          {({ history, match }) => (
            <ProvisionAssetsEditHome
              id={match && match.params.id}
            />
          )}
        </Route>
        <Route path="/dispositivi/:id/dettaglio">
          {({ history, match }) => (
            <DeviceInfoPage
              id={match && match.params.id}
              history={history}
            />
          )}
        </Route>
        <Route path="/dispositivi/:id/segnalazione">
          {({ history, match }) => (
            <DeviceEditHome
              id={match && match.params.id}
            />
          )}
        </Route>
        <Route path="/dispositivi/:id/segnalazione-libro-macchina">
          {({ history, match }) => (
            <DeviceLogBookEditHome
              id={match && match.params.id}
            />
          )}
        </Route>
        <Route path="/dispositivi/:id/segnalazione-ultimo-paziente">
          {({ history, match }) => (
            <DeviceLastPatientAssignedEditHome
              id={match && match.params.id}
            />
          )}
        </Route>
        <ContentRoute path="/dispositivi" component={DevicesPage} />
        <ContentRoute path="/pazienti" component={PatientsPage} />
        <ContentRoute path="/prestazioni" component={ProvisionsPage} />
        {/* <ContentRoute path="/fatture" component={InvoicesPage} /> */}
        {/* <ContentRoute path="/costi" component={CostsPage} /> */}
        {/* <ContentRoute path="/personale" component={StaffsPage} /> */}
        {/* <ContentRoute path="/indicatori-di-servizio" component={ServiceIndicatorsPage} /> */}
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
