import React from "react";
import { Spinner } from "react-bootstrap";
import "../../assets/css/spinner.css";

export function ProgressSpinner({ visible, size }) {
    return (
        <div className="progress-spinner" style={{ display: visible ? "block" : "none" }}>
            <div className="spinner-background" />
            <Spinner animation="border" variant="primary" size={size} />
        </div>
    );
}