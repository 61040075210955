import React from "react";
import { SignoutCallbackComponent } from "redux-oidc";
import userManager from "../../utils/oidcUserManager";
import { connect, useDispatch } from "react-redux";
import * as actions from "../../modules/Auth/_redux/authActions";

function SignoutCallbackPage(props) {

    const dispatch = useDispatch();

    return (
        <SignoutCallbackComponent
            userManager={userManager}
            successCallback={(a, e) => {
                dispatch(actions.logout());
                props.history.push("/auth/login");
            }}
            errorCallback={error => {
                props.history.push("/auth/login");
                console.error(error);
            }}
        >
            <div>Redirecting...</div>
        </SignoutCallbackComponent>
    );
}

export default connect()(SignoutCallbackPage);
