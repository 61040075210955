// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import { ProvisionsTypeTitles } from "../../../pages/provisions/ProvisionsUIHelpers"
import { DeviceLogBookTypeTitle } from "../../../pages/devices/DevicesUIHelpers";
import { documentTypeTitles } from "../../../pages/invoices/InvoicesUIHelpers";
import { PatientGenderTitles } from "../../../pages/patients/PatientsUIHelpers";

export function ProvisionsTypeColumnFormatter(cellContent, row) {
  return (
      ProvisionsTypeTitles[row.ProvisionType]
  );
}

export function LogBookTypeColumnFormatter(cellContent, row) {
  return (
    <span>
      {DeviceLogBookTypeTitle[row.Type]}
    </span>
  );
}

export function InvoicesDocumentTypeColumnFormatter(cellContent, row) {
  return (
    <span>
      {documentTypeTitles[row.DocumentType]}
    </span>
  );
}

export function GenderColumnFormatter(cellContent, row) {
  return (
    <span>
      {PatientGenderTitles[row.Gender]}
    </span>
  );
}


export function MonthYearColumnFormatter(cellContent, row) {
  const monthTitle = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
  let year = row.MonthNumber.substring(0, 4);
  let month = monthTitle[(row.MonthNumber.substring(4) - 1)];
  let monthYear = month + "/" + year;
  return (
    <span>
      {monthYear}
    </span>
  );
}
