import React from "react"
import BootstrapTable from "react-bootstrap-table-next";
import { DownloadProvisionAssetsTableColumnFormatter } from "../../../component/table/column-formatters/ActionsColumnFormatter"

export function ProvisionAssetsTable({ provisionAssets, provisionNote, history, id }) {
    const columns = [
        {
            dataField: "AssetTypeDescription",
            text: "Descrizione",
        },
        {
            dataField: "AssetLink",
            text: "Download",
            formatter: DownloadProvisionAssetsTableColumnFormatter,
            classes: "pr-0",
            headerClasses: "pr-3",
        },
    ];
    return (
        <>
            <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                keyField="Id"
                data={provisionAssets}
                columns={columns}
                noDataIndication={"Non sono presenti dati"}
            >
            </BootstrapTable>
            <div className="form-group">
                <h5 className="col-lg-1 col-xl-1 font-weight-bold mt-10 mb-6">Note</h5>
                <div className="col-lg-12 col-xl-12">
                    <div className="input-group">
                        <textarea className="form-control form-control-solid" disabled readOnly rows="5" value={provisionNote} />
                    </div>
                </div>
            </div>
            <button
                className="btn btn-primary mr-2"
                style={{ float: "right" }}
                onClick={() => history.push(`/prestazioni/${id}/segnalazione-informazioni-dettaglio`)}
            >
                Invia segnalazione
            </button>
        </>
    );
}