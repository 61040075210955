import React, { useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader
} from "../../../../_metronic/_partials/controls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as patientActions from "../../../actions/patients/patientsActions";
import { PatientRegistry } from "./PatientRegistry";
import { PatientInfoMenu } from "./PatientInfoMenu";
import { PatientPrescriptions } from "./PatientPrescriptions";
import { PatientProvisionsTable } from "./PatientProvisionsTable";
import { PatientDevicesTable } from "./PatientDevicesTable";
import { PatientAccessoriesTable } from "./PatientAccessoriesTable";
import { PatientConsumablesTable } from "./PatientConsumablesTable";
import { PatientMedicinalsTable } from "./PatientMedicinalsTable";
import { PatientInvoicesTable } from "./PatientInvoicesTable";
import { PatientChart } from "./PatientChart";
import moment from "moment";

export function PatientInfoHome({ history, id }) {

    const subheader = useSubheader();

    const dispatch = useDispatch();

    const { patient } = useSelector(
        (state) => ({
            patient: state.patients
        }),
        shallowEqual
    );

    const { patientById, patientInvoicesList, patientChartData, patientProvisionsNear } = patient

    useEffect(() => {
        dispatch(patientActions.fetchPatient(id));
        dispatch(patientActions.fetchPatientInvoices(id));
        dispatch(patientActions.fetchPatientChart(id, moment().subtract(1, "year").format("YYYY-MM-DD") + "T00:00:00Z"));
        dispatch(patientActions.fetchPatientProvisionsNear(id))
    }, [id, dispatch]);

    useEffect(() => {
        subheader.setTitle(patientById ? `Pazienti - Profilo Paziente - '${patientById.FullName}'` : "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientById]);

    return (
        <div className="row">
            <div className="col-lg-2">
                <Card className="card-sticky">
                    <CardBody>
                        <PatientInfoMenu />
                    </CardBody>
                </Card>
            </div>
            <div className="col-lg-10">
                <a name="patientGraph" />
                <Card>
                    <CardHeader title="Sintesi Giorni/Servizi Erogati (ultimi 12 mesi)" className="font-weight-bolder" />
                    <CardBody>
                        <PatientChart patientChartData={patientChartData} patientProvisionsNear={patientProvisionsNear} />
                    </CardBody>
                </Card>
                <a name="patientRegistry" />
                <Card>
                    <CardHeader title="Anagrafica" className="font-weight-bolder" />
                    <CardBody>
                        <PatientRegistry patientRegistry={patientById} history={history} />
                    </CardBody>
                </Card>
                <a name="patientPrescriptions" />
                <Card>
                    <CardHeader title="Trattamenti" className="font-weight-bolder" />
                    <CardBody>
                        <PatientPrescriptions prescriptions={patientById ? patientById.Prescriptions : []} history={history} />
                    </CardBody>
                </Card>
                <a name="patientProvisions" />
                <Card>
                    <CardHeader title="Prestazioni (ultime 60)" className="font-weight-bolder" />
                    <CardBody>
                        <PatientProvisionsTable provisions={patientById ? patientById.Provisions : []} history={history} />
                    </CardBody>
                </Card>
                <a name="patientDevices" />
                <Card>
                    <CardHeader title="Dispositivi" className="font-weight-bolder" />
                    <CardBody>
                        <PatientDevicesTable devices={patientById ? patientById.Devices : []} history={history} />
                    </CardBody>
                </Card>
                <a name="patientAccessories" />
                <Card>
                    <CardHeader title="Accessori" className="font-weight-bolder" />
                    <CardBody>
                        <PatientAccessoriesTable accessories={patientById ? patientById.Accessories : []} />
                    </CardBody>
                </Card>
                <a name="patientConsumable" />
                <Card>
                    <CardHeader title="Consumabili" className="font-weight-bolder" />
                    <CardBody>
                        <PatientConsumablesTable consumables={patientById ? patientById.Consumables : []} />
                    </CardBody>
                </Card>
                <a name="patientMedicinals" />
                <Card>
                    <CardHeader title="Farmaci" className="font-weight-bolder" />
                    <CardBody>
                        <PatientMedicinalsTable medicinals={patientById ? patientById.Medicinals : []} />
                    </CardBody>
                </Card>
                <a name="patientInvoices" />
                <Card>
                    <CardHeader title="Importi Fatturati" className="font-weight-bolder" />
                    <CardBody>
                        {
                            patientInvoicesList && patientInvoicesList.length > 0
                                ? <PatientInvoicesTable patientInvoicesList={patientInvoicesList} id={id} history={history} />
                                : <span>Dati al momento non disponibili</span>
                        }
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}