import Swal from 'sweetalert2/src/sweetalert2.js'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

export const modalIconType = {
    success: "success",
    error: "error",
    warning: "warning"
}

export const modalMessageType = {
    requesteditOK: "Invio richiesta di modifica effettuata con successo!",
    requesteditKO: "Siamo spiacenti si è verificato un errore!",
    fieldRequired: "Il campo {0} è obbligatorio"
}

export function SweetAlert(text, icon) {
    MySwal.fire({
        title: text,
        icon: icon,
        confirmButtonText: "Chiudi",
        focusConfirm: false,
        showClass: {
            popup: 'swal2-noanimation',
            backdrop: 'swal2-noanimation'
        },
        willClose: (e) => {
            if (!e.className.includes("warning") && !e.className.includes("error")) {
                window.history.back();
            }
        }
    });
}