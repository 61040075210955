import React from "react";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../_metronic/_helpers";
import BootstrapTable from "react-bootstrap-table-next";
import { defaultPatientAccessoriesSorted } from "./PatientInfoUIHelpers"
import { AssetsColumnFormatter, /*FotoColumnFormatter,*/ AccessoriesFotoColumnFormatter } from "../../../component/table/column-formatters/ActionsColumnFormatter";
import { tableSortByDate } from "../../../component/table/tableSort";

export function PatientAccessoriesTable({ accessories }) {

    const columns = [
        {
            isDummyField: true,
            dataField: "Photo",
            text: "",
            align: "center",
            headerAlign: 'center',
            //formatter: FotoColumnFormatter,
            formatter: AccessoriesFotoColumnFormatter, // soluzione momentanea in quanto a volte arrivano foto 'rotte' dal db appena sistemato rimettere quello sopra
        },
        {
            dataField: "AccessoryType",
            text: "Tipologia",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "ItemNumber",
            text: "Codice Articolo",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "Description",
            text: "Descrizione",
            sort: false,
            align: "center",
            headerAlign: 'center',
        },
        {
            dataField: "Serial",
            text: "Seriale",
            sort: false,
            align: "center",
            headerAlign: 'center',
        },
        {
            dataField: "LotNumber",
            text: "Lotto",
            sort: false,
            align: "center",
            headerAlign: 'center',
        },
        {
            dataField: "DeliveryDate",
            text: "Data Consegna",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortFunc: tableSortByDate,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "PickUpDate",
            text: "Data Ritiro",
            sort: true,
            align: "center",
            headerAlign: 'center',
            sortFunc: tableSortByDate,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            isDummyField: true,
            dataField: "Assets",
            text: "Documentazione",
            align: "center",
            headerAlign: 'center',
            formatter: AssetsColumnFormatter,
        },
    ];
    return (
        <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table-head-custom table-vertical-center"
            bootstrap4
            keyField="Serial"
            data={accessories === null ? [] : accessories}
            columns={columns}
            defaultSorted={defaultPatientAccessoriesSorted}
            noDataIndication={"Non sono presenti dati"}
        >
        </BootstrapTable>
    );
}