export class PatientModel {
  constructor() {
    this.Id = "";
    this.PatientNumbers = [];
    this.FirstName = "";
    this.LastName = "";
    this.FullName = "";
    this.Nominative = "";
    this.BirthDate = "";
    this.Gender = 0;
    this.FiscalCode = "";
    this.District = "";
    this.RegionalCode = "";
    this.PatientState = 0;
    this.Email = "";
    this.Mobile = "";
    this.Phone = "";
    this.HomeAddress = "";
    this.ResidenceAddress = "";
    this.Addresses = [];
    this.CareGiverNominative = "";
    this.DeliveryDate = "";
    this.PickUpDate = "";
    this.Prescriptions = [];
    this.Provisions = [];
    this.Devices = [];
    this.Accessories = [];
    this.Consumables = [];
    this.Medicinals = [];
    this.Invoices = [];
    this.CustomerId = "";
    this.Reporting = "";
  }
}