import React, { useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../_metronic/_partials/controls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as provisionsActions from "../../../actions/provisions/provisionsActions";
import { ProvisionInfoMenu } from "./ProvisionInfoMenu";
import { ProvisionMainInfo } from "./ProvisionMainInfo";
import { ProvisionPatientInfo } from "./ProvisionPatientInfo";
import { ProvisionDevicesTable } from "./ProvisionDevicesTable";
import { ProvisionAccessoriesTable } from "./ProvisionAccessoriesTable";
import { ProvisionConsumablesTable } from "./ProvisionConsumablesTable";
import { ProvisionAssetsTable } from "./ProvisionAssetsTable";

export function ProvisionInfoHome({ history, id }) {

    const subheader = useSubheader();

    const dispatch = useDispatch();

    const { provision } = useSelector(
        (state) => ({
            provision: state.provisions
        }),
        shallowEqual
    );

    const { provisionMainSection, provisionOtherInfo } = provision

    useEffect(() => {
        dispatch(provisionsActions.fetchProvisionMainSection(id));
        dispatch(provisionsActions.fetchProvision(id));
    }, [id, dispatch]);

    useEffect(() => {
        if (provisionMainSection) {
            subheader.setTitle(provisionMainSection.DocumentNumber !== "-" ? `Prestazione - Dettaglio Prestazione - '${provisionMainSection.DocumentNumber}'` : "Prestazione - Dettaglio Prestazione");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [provisionMainSection]);

    return (
        <div className="row">
            <div className="col-lg-2">
                <Card className="card-sticky">
                    <CardBody>
                        <ProvisionInfoMenu />
                    </CardBody>
                </Card>
            </div>
            <div className="col-lg-10">
                <a name="provisionSectionMain" />
                <Card>
                    <CardHeader title="Informazioni Principali" className="font-weight-bolder" />
                    <CardBody>
                        <ProvisionMainInfo provisionMainSection={provisionMainSection} history={history} />
                    </CardBody>
                </Card>
                <a name="provisionPatientInfo" />
                <Card>
                    <CardHeader title="Informazioni Paziente" className="font-weight-bolder" />
                    <CardBody>
                        <ProvisionPatientInfo provisionInfo={provisionOtherInfo} />
                    </CardBody>
                </Card>
                <a name="provisionDevices" />
                <Card>
                    <CardHeader title="Dispositivi" className="font-weight-bolder" />
                    <CardBody>
                        <ProvisionDevicesTable provisionDevices={provisionOtherInfo ? provisionOtherInfo.Devices : []} history={history} provisionType={provisionOtherInfo ? provisionOtherInfo.ProvisionType : 0} />
                    </CardBody>
                </Card>
                <a name="provisionAccessories" />
                <Card>
                    <CardHeader title="Accessori" className="font-weight-bolder" />
                    <CardBody>
                        <ProvisionAccessoriesTable provisionAccessories={provisionOtherInfo ? provisionOtherInfo.Accessories : []} provisionType={provisionOtherInfo ? provisionOtherInfo.ProvisionType : 0} />
                    </CardBody>
                </Card>
                <a name="provisionConsumables" />
                <Card>
                    <CardHeader title="Consumabili" className="font-weight-bolder" />
                    <CardBody>
                        <ProvisionConsumablesTable provisionConsumables={provisionOtherInfo ? provisionOtherInfo.Consumables : []} />
                    </CardBody>
                </Card>
                <a name="provisionInfoDetail" />
                <Card>
                    <CardHeader title="Dettaglio Prestazione" className="font-weight-bolder" />
                    <CardBody>
                        <ProvisionAssetsTable
                            provisionAssets={provisionOtherInfo ? provisionOtherInfo.Assets : []}
                            provisionNote={provisionOtherInfo ? provisionOtherInfo.Note : ""}
                            history={history}
                            id={id}
                        />
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}