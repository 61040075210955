import axios from "axios";
import { path } from "../../utils/constant";

export function findDevices(queryParams) {
    return axios.post(
        path,
        {
            // query: 'query{deviceReview(' + queryParams + '){pageInfo{endCursor hasNextPage}nodes{rowNumber id deviceType brand model description serial lot districtName lastPatientFirstName lastPatientLastName patientDeliveryDate patientPickupDate}}}'
            query: 'query{deviceReview(' + queryParams + '){pageInfo{endCursor hasNextPage}nodes{rowNumber id deviceType brand model description serial districtName lastPatientFirstName lastPatientLastName patientDeliveryDate patientPickupDate}}}'
        });
}

export function getDeviceById(id, customerId) {
    return axios.post(
        path,
        {
            // query: 'query{deviceById(deviceId: "' + id + '", customerId:"' + customerId + '"){nodes{id customerId deviceType brand model description serial lot assets patientDeliveryDate patientPickupDate lastPatientAssigned{id firstName lastName cF district{description}}logBook{iD date type provision{id provisionType patient{id lastName firstName}}district{description}staff{initials}assets}}}}'
            query: 'query{deviceById(deviceId: "' + id + '", customerId:"' + customerId + '"){nodes{id customerId deviceType brand model description serial assets patientDeliveryDate patientPickupDate lastPatientAssigned{id firstName lastName cF district{description}}logBook{iD date type provision{id provisionType patient{id lastName firstName}}district{description}staff{initials}assets}}}}'

        });
}

export function findDevicesFilters(queryParams) {
    return axios.post(
        path,
        {
            //query: 'query {deviceReviewFilters' + queryParams + '{deviceTypes districts lots}}'
            query: 'query {deviceReviewFilters' + queryParams + '{deviceTypes districts}}'
        });
}
