export function tableSortByDate(a, b, order, dataField, rowA, rowB) {
    let indices = [6, 7, 8, 9, 3, 4, 0, 1];
    if (order === 'asc') {
      let r = 0;
      indices.find(i => r = a.charCodeAt(i) - b.charCodeAt(i));
      return r;
    } else if (order === 'desc') {
      let r = 0;
      indices.find(i => r = b.charCodeAt(i) - a.charCodeAt(i));
      return r;
    }
}