import React from "react";
import { ProvisionInfoUIProvider } from "./ProvisionInfoUIContext";
import { ProvisionInfoHome } from "./ProvisionInfoHome";

export const ProvisionsInfoPage = ({ history, id }) => {
    return (
        <ProvisionInfoUIProvider>
            <ProvisionInfoHome history={history} id={id} />
        </ProvisionInfoUIProvider>
    );
}