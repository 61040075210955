import React, { useEffect } from "react";
import { KTScrollCardDetail } from "../../../component/scroll/Scroll";

export function DeviceCard({ deviceDetail, history }) {

    //inizializzo lo scroll dove verrà visualizzata la descrizione del dispositivo
    useEffect(() => {
        KTScrollCardDetail.init("deviceCardDetail")
    }, [deviceDetail]);

    return (
        <>
            {
                deviceDetail
                    ? <div id="deviceCardDetail">
                        <div className="d-flex">
                            <div className="flex-shrink-0 mr-7">
                                <div className="symbol symbol-lg-150">
                                    {
                                        deviceDetail.Photo
                                            ? <img alt="Img" src={deviceDetail.Photo} style={{ width: "150px", height: "auto" }} />
                                            : <span className="navi-icon mr-4">
                                                <span className="svg-icon svg-icon-lg-detail">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path d="M15,15 L15,10 C15,9.44771525 15.4477153,9 16,9 C16.5522847,9 17,9.44771525 17,10 L17,15 L20,15 C21.1045695,15 22,15.8954305 22,17 L22,19 C22,20.1045695 21.1045695,21 20,21 L4,21 C2.8954305,21 2,20.1045695 2,19 L2,17 C2,15.8954305 2.8954305,15 4,15 L15,15 Z M5,17 C4.44771525,17 4,17.4477153 4,18 C4,18.5522847 4.44771525,19 5,19 L10,19 C10.5522847,19 11,18.5522847 11,18 C11,17.4477153 10.5522847,17 10,17 L5,17 Z" fill="#000000" />
                                                            <path d="M20.5,7.7155722 L19.2133304,8.85714286 C18.425346,7.82897283 17.2569914,7.22292937 15.9947545,7.22292937 C14.7366498,7.22292937 13.571742,7.82497398 12.7836854,8.84737587 L11.5,7.70192243 C12.6016042,6.27273291 14.2349886,5.42857143 15.9947545,5.42857143 C17.7603123,5.42857143 19.3985009,6.27832502 20.5,7.7155722 Z M23.5,5.46053062 L22.1362873,6.57142857 C20.629466,4.78945909 18.4012066,3.73944576 15.9963045,3.73944576 C13.5947271,3.73944576 11.3692392,4.78653417 9.8623752,6.56427829 L8.5,5.45180053 C10.340077,3.28094376 13.0626024,2 15.9963045,2 C18.934073,2 21.6599771,3.28451636 23.5,5.46053062 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                        </g>
                                                    </svg>
                                                </span>
                                            </span>
                                    }

                                </div>
                            </div>
                            <div className="flex-grow-1">
                                <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                                    <div className="mr-3">
                                        <span className="d-flex align-items-center text-dark font-size-h5 font-weight-bold mr-3">
                                            {
                                                deviceDetail.Serial + " - " + deviceDetail.BrandAndModel
                                            }
                                        </span>
                                        <div className="d-flex flex-wrap my-2">
                                            {/* <span className="text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">Codice Gruppo: L01A98</span> */}
                                            <span className="text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">Tipologia: {deviceDetail.DeviceType}</span>
                                            {/* <span className="text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">Lotto: {deviceDetail.Lot}</span> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="offcanvas-content" style={{ width: "50%" }}>
                                    {/* {deviceDetail.Description} */}
                                </div>
                            </div>
                        </div>
                        <div className="separator separator-solid my-7" style={{ marginTop: "1rem", marginBottom: "0.5rem" }} />
                        {
                            deviceDetail.Assets.length > 0
                                ? <div className="d-flex align-items-center flex-wrap">
                                    {
                                        deviceDetail.Assets.map((el) => {
                                            let assets = [];
                                            switch (el.AssetType) {
                                                case 1:
                                                    assets.push(
                                                        <div className="d-flex align-items-center flex-lg-fill mr-5 my-1" key={"schedaTecnica"}>
                                                            <a href={el.AssetLink} target="_blank" className="mr-2">
                                                                <span className="svg-icon svg-icon-primary svg-icon-md">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                            <polygon points="0 0 24 0 24 24 0 24" />
                                                                            <path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                                            <rect fill="#000000" x="6" y="11" width="9" height="2" rx="1" />
                                                                            <rect fill="#000000" x="6" y="15" width="5" height="2" rx="1" />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                            </a>
                                                            <div className="d-flex flex-column text-dark-75">
                                                                <a href={el.AssetLink} target="_blank" className="text-primary">Scheda Tecnica</a>
                                                            </div>
                                                        </div>
                                                    )
                                                    break;
                                                case 2:
                                                    assets.push(
                                                        <div className="d-flex align-items-center flex-lg-fill mr-5 my-1" key={"manuale"}>
                                                            <a href={el.AssetLink} target="_blank" className="mr-2">
                                                                <span className="svg-icon svg-icon-primary svg-icon-md">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                            <polygon points="0 0 24 0 24 24 0 24" />
                                                                            <path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                                            <rect fill="#000000" x="6" y="11" width="9" height="2" rx="1" />
                                                                            <rect fill="#000000" x="6" y="15" width="5" height="2" rx="1" />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                            </a>
                                                            <div className="d-flex flex-column text-dark-75">
                                                                <a href={el.AssetLink} target="_blank" className="text-primary">Manuale d'uso</a>
                                                            </div>
                                                        </div>
                                                    )
                                                    break;
                                                case 4:
                                                    assets.push(
                                                        <div className="d-flex align-items-center flex-lg-fill mr-5 my-1" key={"conformita"}>
                                                            <a href={el.AssetLink} target="_blank" className="mr-2">
                                                                <span className="svg-icon svg-icon-primary svg-icon-md">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                            <polygon points="0 0 24 0 24 24 0 24" />
                                                                            <path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                                            <rect fill="#000000" x="6" y="11" width="9" height="2" rx="1" />
                                                                            <rect fill="#000000" x="6" y="15" width="5" height="2" rx="1" />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                            </a>
                                                            <div className="d-flex flex-column text-dark-75">
                                                                <a href={el.AssetLink} target="_blank" className="text-primary">Dichiarazione conformità</a>
                                                            </div>
                                                        </div>
                                                    )
                                                    break;
                                                case 15:
                                                    assets.push(
                                                        <div className="d-flex align-items-center flex-lg-fill mr-5 my-1" key={"ce"}>
                                                            <a href={el.AssetLink} target="_blank" className="mr-2">
                                                                <span className="svg-icon svg-icon-primary svg-icon-md">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                            <polygon points="0 0 24 0 24 24 0 24" />
                                                                            <path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                                            <rect fill="#000000" x="6" y="11" width="9" height="2" rx="1" />
                                                                            <rect fill="#000000" x="6" y="15" width="5" height="2" rx="1" />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                            </a>
                                                            <div className="d-flex flex-column text-dark-75">
                                                                <a href={el.AssetLink} target="_blank" className="text-primary">CE (01/03/2021)</a>
                                                            </div>
                                                        </div>
                                                    )
                                                    break;
                                                case 16:
                                                    assets.push(
                                                        <div className="d-flex align-items-center flex-lg-fill mr-5 my-1" key={"presentazione"}>
                                                            <a href={el.AssetLink} target="_blank" className="mr-2">
                                                                <span className="svg-icon svg-icon-primary svg-icon-md">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                            <polygon points="0 0 24 0 24 24 0 24" />
                                                                            <path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                                            <rect fill="#000000" x="6" y="11" width="9" height="2" rx="1" />
                                                                            <rect fill="#000000" x="6" y="15" width="5" height="2" rx="1" />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                            </a>
                                                            <div className="d-flex flex-column text-dark-75">
                                                                <a href={el.AssetLink} target="_blank" className="text-primary">Presentazione (uso interno)</a>
                                                            </div>
                                                        </div>
                                                    )
                                                    break;
                                                default:
                                                    break;
                                            }
                                            return assets;
                                        })
                                    }
                                </div>
                                : null
                        }
                        <button
                            className="btn btn-primary mr-2"
                            style={{ float: "right" }}
                            onClick={() => history.push(`/dispositivi/${deviceDetail.Id}/segnalazione`)}
                        >
                            Invia segnalazione
                        </button>
                    </div>
                    : null
            }
        </>
    );
}