import React from "react";
import { DevicesUIProvider } from "../DevicesUIContext";
import { DevicesHome } from "./DevicesHome";

export function DevicesPage({ history }) {
  const devicesUIEvents = {
    openInfoDevicePage: (row) => {
      history.push(`/dispositivi/${row.Id}/dettaglio`);
    },
  }

  return (
    <DevicesUIProvider devicesUIEvents={devicesUIEvents}>
      <DevicesHome />
    </DevicesUIProvider>
  );
}
