import React, { useEffect, useMemo } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Chart } from "react-google-charts";
import * as actions from "../../../actions/patients/patientsActions";
import { usePatientsUIContext } from "../PatientsUIContext";
import { getTicks } from "../../../utils/utility";

export function PatientsChart() {

    const patientsUIContext = usePatientsUIContext();
    const patientsUIProps = useMemo(() => {
        return {
            queryParams: patientsUIContext.queryParams,
        };
    }, [patientsUIContext]);

    // Getting curret state of patients list from store (Redux)
    const { currentState } = useSelector(
        (state) => ({ currentState: state.patients }),
        shallowEqual
    );

    const { entitiesChart } = currentState;

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.fetchPatientsChart(patientsUIProps.queryParams));
        charts();
    }, [patientsUIProps.queryParams, dispatch]);

    function charts() {
        if (entitiesChart) {
            let graphs = [];
            let colors = ["#FFAA7F", "#FF552A", "#5D62B5", "#29C3BE", "#F2726F"];
            let colorIndex = 0;
            let chartIndex = 0;

            entitiesChart.forEach((element) => {
                let dataTable = [["Trattamenti", "Pazienti"]];

                let maxValue = 0;

                element.values.forEach((el) => {
                    dataTable.push([el.prescriptionName, el.count]);
                    if (el.count > maxValue) {
                        maxValue = el.count;
                    }
                });

                let graphColors = [];

                if (colors[colorIndex]) {
                    graphColors = [colors[colorIndex]];
                } else {
                    colorIndex = 0;
                    graphColors = [colors[colorIndex]];
                }

                let monthYear = element.values[0].monthName + " " + element.values[0].year;

                let vticks = getTicks(maxValue, 4);

                const chartOptions = {
                    colors: graphColors,
                    fontName: "LindeDaxReg",
                    fontSize: 14,
                    title: monthYear,
                    titleTextStyle: {
                        italic: false,
                        fontSize: 15,
                        color: "#5A5A5A"
                    },
                    chartArea: {
                        width: "100%",
                        left: 60,
                        top: 40,
                        bottom: 70
                    },
                    bar: {
                        groupWidth: 40
                    },
                    hAxis: {
                        title: "Trattamenti",
                        titleTextStyle: {
                            italic: false,
                            color: "#999"
                        },
                        textStyle: {
                            italic: false,
                            color: "#5A5A5A",
                            fontSize: 10
                        }
                    },
                    vAxis: {
                        title: "Pazienti",
                        ticks: vticks,
                        titleTextStyle: {
                            italic: false,
                            color: "#999"
                        },
                        textStyle: {
                            italic: false,
                            color: "#5A5A5A"
                        },
                        baselineColor: "#F2F2F2",
                        gridlines: {
                            color: "#F2F2F2"
                        }
                    },
                    legend: {
                        position: "none",
                    }
                };

                graphs.push(
                    <Chart chartType="ColumnChart" height={200} data={dataTable} options={chartOptions} key={"chart" + chartIndex} />
                );

                colorIndex++;
                chartIndex++;
            });

            return graphs;
        }
        else {
            return (
                <span>Non sono presenti dati</span>
            );
        }
    }

    return (
        charts()
    );
}