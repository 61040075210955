import { createSlice } from "@reduxjs/toolkit";
import { PaginationModel } from "../../models/paginationModel";
import { DeviceTableModel } from "../../models/deviceTableModel";
import moment from "moment";
import { getProvisionTypeValue } from "../../pages/provisions/ProvisionsUIHelpers";
import { PatientModel } from "../../models/patientModel";
import { DeviceModel } from "../../models/deviceModel";
import { LogBookModel } from "../../models/logBookModel";
import { getDeviceLogBookTypeValue } from "../../pages/devices/DevicesUIHelpers";
//import { AccessorieModel } from "../../models/accessorieModel";

const initialDevicesState = {
    selectedDeviceLogBook: [],
    selectedDeviceLastPatientAssigned: [],
    selectedDeviceAccessorie: [],
    pagination: new PaginationModel(),
    devicesList: [],
    deviceDetail: undefined,
    filterDevicesType: [],
    //filterDevicesLot: [],
    filterDevicesDistrict: [],
};

export const devicesSlice = createSlice({
    name: "devices",
    initialState: initialDevicesState,
    reducers: {
        devicesFitlersFetched: (state, action) => {
            try {
                const { entities } = action.payload;
                if (entities.data.deviceReviewFilters) {
                    let types = entities.data.deviceReviewFilters.deviceTypes;
                    let districts = entities.data.deviceReviewFilters.districts;
                    //let lots = entities.data.deviceReviewFilters.lots;

                    let districtsArray = [];
                    districts.forEach((e) => {
                        if (e) {
                            districtsArray.push(e);
                        } else {
                            districtsArray.push("-");
                        }
                    });

                    state.filterDevicesDistrict = districtsArray.sort();

                    state.filterDevicesType = types.sort().map((el) => {
                        return { value: el, label: el }
                    });

                    // state.filterDevicesLot = lots.sort().map((el) => {
                    //     return { value: el, label: el }
                    // });
                } else {
                    state.filterDevicesDistrict = [];
                    state.filterDevicesType = [];
                    //state.filterDevicesLot = [];
                }
            } catch (error) {
                console.error(error);
            }
        },
        deviceFetched: (state, action) => {
            try {
                const { deviceDetail } = action.payload;
                if (deviceDetail.data.deviceById) {
                    let device = deviceDetail.data.deviceById.nodes[0];
                    let deviceModel = new DeviceModel();
                    deviceModel.Id = device.id;
                    deviceModel.CustomerId = device.customerId;
                    deviceModel.DeviceType = device.deviceType;
                    deviceModel.BrandAndModel = /*device.brand + " " + device.model*/device.description;
                    deviceModel.Description = device.description;
                    deviceModel.Serial = device.serial;
                    //deviceModel.Lot = device.lot ? device.lot : "-";

                    if (device.assets) {
                        let assets = JSON.parse(device.assets);
                        assets.forEach((el) => {
                            if (el.AssetType === 0) {
                                deviceModel.Photo = el.AssetLink;
                            } else {
                                deviceModel.Assets.push(el);
                            }
                        });
                    }

                    let deviceLastPatient = device.lastPatientAssigned;
                    if (deviceLastPatient) {
                        let deviceLastPatientAssigned = new PatientModel();

                        deviceLastPatientAssigned.Id = deviceLastPatient.id;
                        deviceLastPatientAssigned.CustomerId = device.customerId;
                        deviceLastPatientAssigned.Nominative = deviceLastPatient.lastName + " " + deviceLastPatient.firstName;
                        deviceLastPatientAssigned.FiscalCode = deviceLastPatient.cF ? deviceLastPatient.cF : "-";
                        deviceLastPatientAssigned.District = deviceLastPatient.district ? deviceLastPatient.district.description : "-";
                        deviceLastPatientAssigned.DeliveryDate = device.patientDeliveryDate ? moment(device.patientDeliveryDate).format("DD/MM/YYYY") : "-";
                        deviceLastPatientAssigned.PickUpDate = device.patientPickupDate ? moment(device.patientPickupDate).format("DD/MM/YYYY") : "-";

                        deviceModel.UltimatePatientAssigned.push(deviceLastPatientAssigned);
                    }

                    if (device.logBook && device.logBook.length > 0) {
                        let newLogBookArray = [];
                        device.logBook.forEach((el) => {
                            let logBookModel = new LogBookModel();
                            logBookModel.Id = el.iD;
                            logBookModel.CustomerId = device.customerId;
                            logBookModel.Date = moment(el.date).format("DD/MM/YYYY");
                            logBookModel.Type = getDeviceLogBookTypeValue(el.type);
                            logBookModel.District = el.district ? el.district.description : "-";
                            logBookModel.StaffInitials = el.staff ? el.staff.initials : "-"
                            logBookModel.ProvisionId = el.provision ? el.provision.id.replace('/', '-') : "-";
                            logBookModel.ProvisionType = el.provision ? getProvisionTypeValue(el.provision.provisionType) : "-";
                            logBookModel.PatientId = el.provision ? el.provision.patient.id : "";
                            logBookModel.PatientFullName = el.provision ? el.provision.patient.lastName + " " + el.provision.patient.firstName : "-";
                            newLogBookArray.push(logBookModel);
                        });
                        deviceModel.LogBook = newLogBookArray
                    }

                    // if (device.accessories && device.accessories.length > 0) {
                    //     let newAccessoriesArray = [];
                    //     device.accessories.forEach((el) => {
                    //         let accessorieModel = new AccessorieModel();
                    //         accessorieModel.Id = el.id;
                    //         accessorieModel.AccessoryType = el.accessoryType;
                    //         accessorieModel.ItemNumber = el.itemCode?el.itemCode:"-";
                    //         accessorieModel.Description = el.description;
                    //         accessorieModel.Serial = el.serial ? el.serial : "-";
                    //         accessorieModel.LotNumber = el.lot ? el.lot : "-";
                    //         accessorieModel.DeliveryDate = el.patientDeliveryDate ? moment(el.patientDeliveryDate).format("DD/MM/YYYY") : "-";
                    //         accessorieModel.PickUpDate = el.patientPickUpDate ? moment(el.patientPickUpDate).format("DD/MM/YYYY") : "-";
                    //         if (el.assets) {
                    //             let assets = JSON.parse(el.assets);
                    //             assets.forEach((el) => {
                    //                 if (el.AssetType === 0) {
                    //                     accessorieModel.Photo = el.AssetLink;
                    //                 } else {
                    //                     accessorieModel.Assets.push(el);
                    //                 }
                    //             });
                    //         }
                    //         newAccessoriesArray.push(accessorieModel);
                    //     });
                    //     deviceModel.Accessories = newAccessoriesArray
                    // }

                    state.deviceDetail = deviceModel;

                } else {
                    state.deviceDetail = undefined;
                }
            } catch (error) {
                console.error(error);
            }
        },
        devicesFetched: (state, action) => {
            try {
                const { entities, holdPagination, isChangedSort } = action.payload;
                if (entities.data.deviceReview) {
                    let newDevicesList = [];
                    entities.data.deviceReview.nodes.forEach((el) => {
                        let model = new DeviceTableModel();
                        model.RowNumber = el.rowNumber;
                        model.Id = el.id;
                        model.DeviceType = el.deviceType ? el.deviceType : "-";
                        model.BrandAndModel = /*el.brand + " " + el.model*/ el.description;
                        model.Serial = el.serial ? el.serial : "-";
                        //model.Lot = el.lot ? el.lot : "-";
                        model.District = el.districtName;
                        model.TotalHoursOfUse = "-";
                        model.LastPatientAssigned = el.lastPatientLastName ? el.lastPatientLastName + " " + el.lastPatientFirstName : "-";
                        model.DeliveryDate = el.patientDeliveryDate ? moment(el.patientDeliveryDate).format("DD/MM/YYYY") : "-";
                        model.PickUpDate = el.patientPickupDate ? moment(el.patientPickupDate).format("DD/MM/YYYY") : "-";

                        newDevicesList.push(model);
                    });

                    let pageInfo = entities.data.deviceReview.pageInfo;
                    let newPagination = new PaginationModel();
                    newPagination.EndCursor = pageInfo.endCursor;
                    newPagination.HasNextPage = pageInfo.hasNextPage;

                    if (isChangedSort) {
                        state.devicesList = newDevicesList;
                    } else {
                        state.devicesList = state.devicesList.length > 0 && holdPagination ? state.devicesList.concat(newDevicesList) : newDevicesList;
                    }
                    state.pagination = newPagination;
                } else {
                    state.devicesList = [];
                    state.pagination = new PaginationModel();
                }
            } catch (error) {
                console.error(error);
            }
        },
        devicesForFileFetched: (state, action) => {
            try {
                const { entities, callback } = action.payload;
                let newDevicesList = [];
                if (entities.data.deviceReview) {
                    entities.data.deviceReview.nodes.forEach((el) => {
                        let model = new DeviceTableModel();
                        model.RowNumber = el.rowNumber;
                        model.Id = el.id;
                        model.DeviceType = el.deviceType ? el.deviceType : "-";
                        model.BrandAndModel = /*el.brand + " " + el.model*/ el.description;
                        model.Serial = el.serial ? el.serial : "-";
                        //model.Lot = el.lot ? el.lot : "-";
                        model.District = el.districtName;
                        model.TotalHoursOfUse = "-";
                        model.LastPatientAssigned = el.lastPatientLastName ? el.lastPatientLastName + " " + el.lastPatientFirstName : "-";
                        model.DeliveryDate = el.patientDeliveryDate ? moment(el.patientDeliveryDate).format("DD/MM/YYYY") : "-";
                        model.PickUpDate = el.patientPickUpDate ? moment(el.patientPickUpDate).format("DD/MM/YYYY") : "-";

                        newDevicesList.push(model);
                    });
                }
                callback(newDevicesList);
            } catch (error) {
                console.error(error);
            }
        },
        setLogBookRow: (state, action) => {
            try {
                state.selectedDeviceLogBook = action.payload.data;
            } catch (error) {
                console.error(error);
            }
        },
        setLastPatientAssignedRow: (state, action) => {
            try {
                state.selectedDeviceLastPatientAssigned = action.payload.data;
            } catch (error) {
                console.error(error);
            }
        },
        setAccessorieRow: (state, action) => {
            try {
                state.selectedDeviceAccessorie = action.payload.data;
            } catch (error) {
                console.error(error);
            }
        },
    }
});