export class InvoiceModel {
    constructor() {
        this.Id = "";
        this.MonthYear = "";
        this.MonthNumber = 0;
        this.PrescriptionName = "";
        this.PrescriptorName = "";
        this.InchargeDate = "";
        this.DischargeDate = "";
        this.TotalAmount = "";
        this.TherapyDays = 0;
        this.CustomerId = "";
        this.Reporting = "";
    }
}