export class CostModel {
    constructor() {
        this.ClientId = "";
        this.District = "";
        this.Prescription = "";
        this.Prescriptor = "";
        this.TherapyDays = 0;
        this.MonthNumber = 0;
        this.Amount = 0;
        this.CostId = 0;
        this.PatientFullName = "";
        this.PatientFiscalCode = "";
        this.PatientId = "";
        this.PatientStatus = 0;
        this.InchargeDate = "";
        this.DischargeDate = "";
        this.MonthYear = "";
    }
}