import React from "react";
import { Dropdown } from 'react-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { PatientGenderTitles, PatientStatusTitles } from '../../pages/patients/PatientsUIHelpers';
import { ProvisionStatusTitles, ProvisionsTypeTitles } from '../../pages/provisions/ProvisionsUIHelpers';
import { documentTypeTitles } from "../../pages/invoices/InvoicesUIHelpers";
import moment from "moment";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export function ExportButton({ data, columns, fileName, loadData }) {

    const unixMilliseconds = +moment();

    function getColumnForFiles() {
        let tableColumns = [];
        columns.forEach((el) => {
            if (el.text !== "CV" && el.text !== "Azioni" && el.text !== "Download") {
                tableColumns.push(el.text);
            }
        });
        return tableColumns;
    }

    function getDataForFiles(dataFile) {
        let dataColomns = []

        dataFile.forEach((el) => {
            let values = [];

            for (let field in el) {
                let fieldPresent = columns.filter((e) => { return e.dataField == field }).length;
                if (fileName === "pazienti") {
                    if (field === "Status") {
                        values.push(PatientStatusTitles[el[field]]);
                    }
                    else if (field === "Gender") {
                        values.push(PatientGenderTitles[el[field]]);
                    } else if (fieldPresent > 0) {
                        values.push(el[field]);
                    }
                } else if (fileName === "prestazioni") {
                    if (field === "Status") {
                        values.push(ProvisionStatusTitles[el[field]]);
                    } else if (field === "ProvisionType") {
                        values.push(ProvisionsTypeTitles[el[field]]);
                    } else if (fieldPresent > 0) {
                        values.push(el[field]);
                    }
                } else if (fileName === "dispositivi") {
                    if (fieldPresent > 0) {
                        values.push(el[field]);
                    }
                } else if (fileName === "personale") {
                    if (fieldPresent > 0) {
                        values.push(el[field]);
                    }
                } else if (fileName === "costi") {
                    if (field === "MonthNumber") {
                        const monthTitle = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
                        let year = el.MonthNumber.substring(0, 4);
                        let month = monthTitle[(el.MonthNumber.substring(4) - 1)];
                        let monthYear = month + "/" + year;
                        values.push(monthYear);
                    } else if (fieldPresent > 0) {
                        values.push(el[field]);
                    }
                } else if (fileName === "indicatori-di-servizio") {
                    if (field === "MonthNumber") {
                        const monthTitle = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
                        let year = el.MonthNumber.substring(0, 4);
                        let month = monthTitle[(el.MonthNumber.substring(4) - 1)];
                        let monthYear = month + "/" + year;
                        values.push(monthYear);
                    } else if (fieldPresent > 0) {
                        values.push(el[field]);
                    }
                } else {
                    if (field === "DocumentType") {
                        values.push(documentTypeTitles[el[field]]);
                    } else if (fieldPresent > 0) {
                        values.push(el[field]);
                    }
                }
            }
            dataColomns.push(values);
        });

        return dataColomns;
    }

    function downloadCSV() {
        if (data && data.length > 0) {
            downloadCSVCallback(data);
        } else {
            loadData(downloadCSVCallback);
        }
    }

    async function downloadCSVCallback(dataCSV) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(fileName);
        var sheetColumns = [];
        columns.forEach((el) => {
            sheetColumns.push(
                {
                    header: el.text,
                    key: el.dataField
                }
            );
        });
        worksheet.columns = sheetColumns;
        worksheet.addRows(getDataForFiles(dataCSV));

        var CSVFilename = 'elenco-' + fileName + '-' + unixMilliseconds + '.csv';

        const buffer = await workbook.csv.writeBuffer();
        const fileType = 'text/plain;charset=utf-8';
        const blob = new Blob([buffer], { type: fileType });
        saveAs(blob, CSVFilename);
    }

    function downloadExcel() {
        if (data && data.length > 0) {
            downloadExcelCallback(data);
        } else {
            loadData(downloadExcelCallback);
        }
    }

    async function downloadExcelCallback(dataExcel) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(fileName);
        var sheetColumns = [];
        columns.forEach((el) => {
            sheetColumns.push(
                {
                    header: el.text,
                    key: el.dataField
                }
            );
        });
        worksheet.columns = sheetColumns;
        worksheet.addRows(getDataForFiles(dataExcel));

        var excelFilename = 'elenco-' + fileName + '-' + unixMilliseconds + '.xlsx';

        const buffer = await workbook.xlsx.writeBuffer();
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const blob = new Blob([buffer], { type: fileType });
        saveAs(blob, excelFilename);
    }

    function downloadPDF() {
        if (data && data.length > 0) {
            downloadPdfCallback(data);
        } else {
            loadData(downloadPdfCallback);
        }
    }

    function downloadPdfCallback(dataPdf) {
        const doc = new jsPDF("l");

        doc.autoTable({
            head: [getColumnForFiles()],
            body: getDataForFiles(dataPdf)
        });

        doc.save('elenco-' + fileName + '-' + unixMilliseconds + '.pdf');
    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <button
            className="btn btn-primary dropdown-toggle"
            id="dropdown-basic-button"
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            <span className="svg-icon svg-icon-md">
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                        <path d="M14.8875071,11.8306874 L12.9310336,11.8306874 L12.9310336,9.82301606 C12.9310336,9.54687369 12.707176,9.32301606 12.4310336,9.32301606 L11.4077349,9.32301606 C11.1315925,9.32301606 10.9077349,9.54687369 10.9077349,9.82301606 L10.9077349,11.8306874 L8.9512614,11.8306874 C8.67511903,11.8306874 8.4512614,12.054545 8.4512614,12.3306874 C8.4512614,12.448999 8.49321518,12.5634776 8.56966458,12.6537723 L11.5377874,16.1594334 C11.7162223,16.3701835 12.0317191,16.3963802 12.2424692,16.2179453 C12.2635563,16.2000915 12.2831273,16.1805206 12.3009811,16.1594334 L15.2691039,12.6537723 C15.4475388,12.4430222 15.4213421,12.1275254 15.210592,11.9490905 C15.1202973,11.8726411 15.0058187,11.8306874 14.8875071,11.8306874 Z" fill="#000000" />
                    </g>
                </svg>
            </span>
            {children}
        </button>
    ));

    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    <ul className="navi flex-column navi-hover py-2">
                        <li className="navi-header font-weight-bolder text-uppercase font-size-sm text-primary pb-2">Scegli un'opzione:</li>
                        {
                            React.Children.toArray(children).map(
                                (el, index) =>
                                    el.props.children === "CSV"
                                        ? <li key={"liCSV" + index} className="navi-item" onClick={() => { downloadCSV(); }}>
                                            <div className="navi-link">
                                                <span className="navi-icon">
                                                    <i className="far fa-file-alt" />
                                                </span>
                                                <span className="navi-text">{el.props.children}</span>
                                            </div>
                                        </li>
                                        : el.props.children === "Excel"
                                            ? <li key={"liExcel" + index} className="navi-item" onClick={() => { downloadExcel(); }}>
                                                <div className="navi-link">
                                                    <span className="navi-icon">
                                                        <i className="far fa-file-excel" />
                                                    </span>
                                                    <span className="navi-text">{el.props.children}</span>
                                                </div>
                                            </li>
                                            : <a key={"liPDF" + index} className="navi-item" onClick={() => { downloadPDF(); }}>
                                                <div className="navi-link">
                                                    <span className="navi-icon">
                                                        <i className="far fa-file-pdf" />
                                                    </span>
                                                    <span className="navi-text">{el.props.children}</span>
                                                </div>
                                            </a>
                            )
                        }
                    </ul>
                </div >
            );
        },
    );

    return (
        <Dropdown className="dropdown-inline mr-2">
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                Esporta
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu} className="dropdown-menu-sm dropdown-menu-right">
                <Dropdown.Item eventKey="1">Excel</Dropdown.Item>
                <Dropdown.Item eventKey="2">CSV</Dropdown.Item>
                <Dropdown.Item eventKey="3">PDF</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}