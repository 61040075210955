import React, { useEffect/*, useState*/ } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import $ from "jquery";
import "../../assets/css/table.css";

export function InfiniteScrollTable({ ...props }) {
    let debounceTimeoutId;

    window.onscroll = () => {
        clearTimeout(debounceTimeoutId);
        debounceTimeoutId = setTimeout(() => {
            if (props.pagination.HasNextPage && $(window).scrollTop() + $(window).height() > $(document).height() - 150) {
                props.loadData();
            }
        }, 500);
    };

    useEffect(() => {
        return () => {
            window.onscroll = null;
        }
    }, []);

    const selectRow = {
        mode: "checkbox",
        clickToSelect: true,
        hideSelectAll: true,
        selectionRenderer: ({ mode, checked, disabled }) => {
            return (
                <label className="checkbox checkbox-single">
                    <input type="checkbox" />
                    <span />
                </label>
            );
        },
        onSelect: (row, isSelect, rowIndex, e) => {
            if (e.target.localName === "td") {
                if (props.onSelectRow) {
                    props.onSelectRow(row);
                }
            } else if (e.target.localName === "input") {
                props.onSelectCheckBox(row, e.target.checked);
            }
        }
    }

    return (
        <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            id={props.id}
            remote={true}
            hover={props.hoverEnable}
            classes="table-head-custom table-vertical-center"
            bootstrap4
            keyField={props.keyField}
            data={props.data}
            columns={props.columns}
            defaultSorted={props.defaultSorted}
            selectRow={selectRow}
            onTableChange={props.loadData}
            noDataIndication={props.noData}
        >
        </BootstrapTable>
    )
}